// @flow
import React, { useState, useEffect, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import connect from 'react-redux/es/connect/connect';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import Tunnels from '../../components/Tunnels';
import { deleteCustomTunnel, getTunnels } from '../../api/tunnel-api';
import LoadingPane from '../../components/LoadingPane';
import { wrapActionCreators } from '../../utils/container-util';
import { getTunnelStatuses } from '../../actions/tunnel-actions';
import {
    getFilters,
    getSearchQuery,
    getUiFilters
} from '../../selectors/tunnels-selectors';
import { setGlobalMessageError } from '../../actions/app-actions';
import {
    constructGlobalErrorMessage,
    parseErrorMessage
} from '../../utils/global-message-util';
import { getLoggedInUser } from '../../selectors/app-selectors';
import { selectDevicesStatusesData } from '../../selectors/devices-selectors';
import useUnmounted from '../../hooks/use-unmount';

const TunnelsHOC = ({
    searchQuery,
    uiFilters,
    filters,
    actions: { getTunnelStatuses, setGlobalMessageError },
    devicesStatuses
}) => {
    const unMounted = useUnmounted();
    const [allTunnelsCount, setAllTunnelsCount] = useState(0);
    const [tunnels, setTunnels] = useState([]);
    const [getTunnelsError, setGetTunnelsError] = useState('');
    const [getTunnelsLoading, setGetTunnelsLoading] = useState(false);
    const [firstTime, setFirstTime] = useState(true);
    const [stoppingTunnelsInProcess, setStoppingTunnelsInProcess] = useState(
        false
    );
    const [reloadingTunnelsInProcess, setReloadingTunnelsInProcess] = useState(
        false
    );
    const [filteredTunnels, setFilteredTunnels] = useState(tunnels);
    const sortTunnels = tunnels =>
        tunnels.sort((t1, t2) => {
            if (
                (t1.favourite && t2.favourite) ||
                (!t1.favourite && !t2.favourite)
            ) {
                return 0;
            }
            if (!t1.favourite && t2.favourite) {
                return 1;
            }
            return -1;
        });
    useEffect(() => {
        getTunnelStatuses();
        const intervalId = setInterval(getTunnelStatuses, 60000);
        return () => {
            clearTimeout(intervalId);
        };
    }, []);

    const getTunnelsData = (s, f) => {
        setGetTunnelsLoading(true);
        getTunnels(s, f).subscribe(
            tunnels => {
                if (!unMounted.current) {
                    setGetTunnelsError('');
                    setGetTunnelsLoading(false);
                    setFirstTime(false);
                    if (tunnels && tunnels.data) {
                        setTunnels(sortTunnels(tunnels.data.tunnels));
                        setAllTunnelsCount(tunnels.data.allTunnelsCount);
                    }
                }
            },
            err => {
                let message = 'Something went wrong';
                if (err) message = parseErrorMessage(err);
                setGlobalMessageError(constructGlobalErrorMessage(message));
                if (!unMounted.current) {
                    setGetTunnelsError(message);
                    setGetTunnelsLoading(false);
                    setFirstTime(false);
                }
            }
        );
    };

    useEffect(() => {
        getTunnelsData(searchQuery, filters);
    }, []);

    const getTunnelsDataDebounce = useCallback(
        debounce((s, f) => {
            getTunnelsData(s, f);
        }, 300),
        []
    );

    useEffect(
        () => {
            if (firstTime) return;
            getTunnelsDataDebounce(searchQuery, filters);
        },
        [searchQuery, filters]
    );

    const determineAvailability = lastSeen => {
        const newDate = Date.now();

        if (!lastSeen) {
            return 'offline';
        }

        const diffMs = newDate - lastSeen;
        const diffMins = Math.round(diffMs / (1000 * 60));
        if (diffMins > 10) {
            return 'offline';
        }
        if (diffMins > 3) {
            return 'away';
        }
        return 'online';
    };

    const uiFilterFn = t => {
        const tunnelStatusFilter = uiFilters.find(
            uiF => uiF.property === 'tunnelStatus'
        ).value;
        const deviceStatusFilter = uiFilters.find(
            uiF => uiF.property === 'deviceStatus'
        ).value;
        const { status } = t;
        let deviceStatus = 'offline';
        let lastSeen;
        try {
            const statuses = devicesStatuses[t.deviceId];
            if (statuses) {
                const data = JSON.parse(statuses);
                lastSeen = data ? data.lastSeen : undefined;
            }
        } catch {
            //
        }
        if (lastSeen) {
            deviceStatus = determineAvailability(lastSeen);
        }
        return (
            (tunnelStatusFilter === status || tunnelStatusFilter === 'all') &&
            (deviceStatusFilter === deviceStatus ||
                deviceStatusFilter === 'all')
        );
    };

    useEffect(
        () => {
            setFilteredTunnels(tunnels.filter(uiFilterFn));
        },
        [tunnels, uiFilters]
    );

    const onStopAllRunningTunnels = () => {
        setStoppingTunnelsInProcess(true);
    };

    const onReloadAllRunningTunnels = () => {
        setReloadingTunnelsInProcess(true);
    };

    const onReloadAllRunningTunnelsDone = () => {
        setReloadingTunnelsInProcess(false);
    };

    const onStopAllRunningTunnelsDone = () => {
        setStoppingTunnelsInProcess(false);
    };

    const tunnelDelete = tunnelId =>
        deleteCustomTunnel(tunnelId).subscribe(
            () => {
                const newTunnels = [...tunnels];
                const deletedTunnelIndex = newTunnels.indexOf(
                    newTunnels.find(t => t.id === tunnelId)
                );
                if (deletedTunnelIndex > -1) {
                    newTunnels.splice(deletedTunnelIndex, 1);
                }
                setTunnels(newTunnels);
                setAllTunnelsCount(allTunnelsCount - 1);
            },
            err => {
                let message = 'Something went wrong';
                if (err) message = parseErrorMessage(err);
                setGlobalMessageError(constructGlobalErrorMessage(message));
            }
        );

    const onTunnelReady = tunnel => {
        if (tunnel) {
            setTunnels([tunnel.data.tunnel, ...tunnels]);
            setAllTunnelsCount(allTunnelsCount + 1);
        }
    };

    // const mergeUpdatedTunnels = updatedTunnels => {
    //     const newTunnels = [...tunnels];
    //     updatedTunnels.forEach(tunnel => {
    //         const updatedTunnelIndex = newTunnels.indexOf(
    //             newTunnels.find(t => t.id === tunnel.id)
    //         );
    //         if (updatedTunnelIndex > -1) {
    //             newTunnels.splice(updatedTunnelIndex, 1, tunnel);
    //         }
    //     });
    //     setTunnels(newTunnels);
    //     setStoppingTunnelsInProcess(false);
    // };

    return (
        <>
            {firstTime && getTunnelsLoading ? (
                <LoadingPane loading>
                    <div>Loading</div>
                </LoadingPane>
            ) : (
                <Tunnels
                    allTunnelsCount={allTunnelsCount}
                    tunnels={filteredTunnels}
                    getTunnelsError={getTunnelsError}
                    getTunnelsLoading={getTunnelsLoading}
                    onStopAllRunningTunnels={onStopAllRunningTunnels}
                    onReloadAllRunningTunnels={onReloadAllRunningTunnels}
                    tunnelDelete={tunnelDelete}
                    onTunnelReady={onTunnelReady}
                    stoppingTunnelsInProcess={stoppingTunnelsInProcess}
                    reloadingTunnelsInProcess={reloadingTunnelsInProcess}
                    onReloadAllRunningTunnelsDone={
                        onReloadAllRunningTunnelsDone
                    }
                    onStopAllRunningTunnelsDone={onStopAllRunningTunnelsDone}
                />
            )}
        </>
    );
};

const mapStateToProps = createStructuredSelector({
    loggedInUser: getLoggedInUser(),
    searchQuery: getSearchQuery(),
    filters: getFilters(),
    uiFilters: getUiFilters(),
    devicesStatuses: selectDevicesStatusesData()
});

const mapDispatchToProps = wrapActionCreators({
    setGlobalMessageError,
    getTunnelStatuses
});

TunnelsHOC.propTypes = {
    actions: PropTypes.shape({
        getTunnelStatuses: PropTypes.func.isRequired,
        setGlobalMessageError: PropTypes.func.isRequired
    }).isRequired,
    searchQuery: PropTypes.string.isRequired,
    loggedInUser: PropTypes.object.isRequired,
    filters: PropTypes.array.isRequired,
    uiFilters: PropTypes.array.isRequired,
    devicesStatuses: PropTypes.object.isRequired
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(TunnelsHOC)
);
