import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { Link, Tooltip } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/HelpOutline';
import { buildRdpFile } from '../../../utils/rdp-config';
import ClipboardCopy from '../../ClipboardCopy';
import { RDP_HELP_URL } from '../../../constants/api-constants';

const styles = () => ({});

const RDPModal = props => {
    const {
        fullScreen,
        close,
        rdpConfig: {
            tunnel: {
                deviceName,
                engine: { address },
                clientPort
            }
        }
    } = props;

    const href = `data:application/rdp;charset=utf-8,${encodeURIComponent(
        buildRdpFile({ address: `${address}:${clientPort}` })
    )}`;
    return (
        <Dialog fullScreen={fullScreen} open onClose={close}>
            <DialogContent>
                <Typography>
                    Your RDP tunnel to device will be available in few seconds.
                </Typography>
                <br />
                <Typography>
                    <div style={{ display: 'flex', fontWeight: 'bold' }}>
                        Remote Desktop Connection file
                        <Tooltip
                            title=" The RDP file type is primarily associated with Remote Desktop Connection by Microsoft Corporation. An .RDP file contains all of the information for a connection to a terminal server, including the options settings that were configured when the file was saved"
                            placement="bottom"
                        >
                            <HelpIcon
                                style={{ marginLeft: 5 }}
                                fontSize="small"
                                color="primary"
                            />
                        </Tooltip>
                    </div>
                </Typography>
                <Typography>
                    <Link
                        target="_blank"
                        download={`${deviceName}.rdp`}
                        rel="noopener noreferrer"
                        href={href}
                    >
                        Download settings file (.RDP )
                    </Link>
                </Typography>
                <br />
                <Typography style={{ fontWeight: 'bold' }}>
                    For manual configuration use following:
                </Typography>
                <Typography>
                    Address: <ClipboardCopy text={address} value={address} />
                </Typography>
                <Typography>
                    Port: <ClipboardCopy text={clientPort} value={clientPort} />
                </Typography>
                <br />
                <Link
                    style={{ marginTop: 15 }}
                    rel="noopener noreferrer"
                    target="_blank"
                    href={RDP_HELP_URL}
                >
                    Click here for additional information about RDP
                    configuration.
                </Link>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="primary" onClick={close}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

RDPModal.propTypes = {
    fullScreen: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    rdpConfig: PropTypes.object.isRequired
};

export default withMobileDialog()(withStyles(styles)(RDPModal));
