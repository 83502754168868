// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { Link, Tooltip } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/HelpOutline';
import { BASE_DOMAIN, PROXY_HELP_URL } from '../../../constants/api-constants';
import ClipboardCopy from '../../ClipboardCopy';

const styles = () => ({});

const ProxyModal = props => {
    const {
        fullScreen,
        close,
        loggedInUser,
        proxyConfig: {
            tunnel: {
                engine: { address },
                clientPort
            }
        }
    } = props;
    return (
        <Dialog fullScreen={fullScreen} open onClose={close}>
            <DialogContent>
                <Typography>
                    Your proxy tunnel to device will be available in few
                    seconds.
                </Typography>
                <br />
                <Typography>
                    <div style={{ display: 'flex', fontWeight: 'bold' }}>
                        Proxy auto-config URL
                        <Tooltip
                            title=" A proxy auto-config (PAC) file defines how web browsers and other user agents can automatically choose the appropriate proxy server (access method) for fetching a given URL"
                            placement="bottom"
                        >
                            <HelpIcon
                                style={{ marginLeft: 5 }}
                                fontSize="small"
                                color="primary"
                            />
                        </Tooltip>
                    </div>
                </Typography>
                <Typography>
                    <ClipboardCopy
                        text={`${BASE_DOMAIN}/${loggedInUser.username}`}
                        value={`${BASE_DOMAIN}/${loggedInUser.username}`}
                    />
                </Typography>
                <br />
                <Typography style={{ fontWeight: 'bold' }}>
                    For manual configuration use following:
                </Typography>

                <Typography>
                    Address: <ClipboardCopy text={address} value={address} />
                </Typography>
                <Typography>
                    Port: <ClipboardCopy text={clientPort} value={clientPort} />
                </Typography>
                <br />
                <Link
                    style={{ marginTop: 15 }}
                    rel="noopener noreferrer"
                    target="_blank"
                    href={PROXY_HELP_URL}
                >
                    Click here for additional information about proxy
                    configuration.
                </Link>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="primary" onClick={close}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};
ProxyModal.propTypes = {
    fullScreen: PropTypes.bool.isRequired,
    loggedInUser: PropTypes.object.isRequired,
    close: PropTypes.func.isRequired,
    proxyConfig: PropTypes.object.isRequired
};
export default withMobileDialog()(withStyles(styles)(ProxyModal));
