// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, withRouter } from 'react-router-dom';
import HTML5Backend from 'react-dnd-html5-backend';
import { DragDropContext } from 'react-dnd';

import withMaterial from '../withMaterial';
import Login from '../../containers/Authentication/Login';
import Signup from '../../containers/Authentication/Signup';
import OrganizationInvitation from '../../containers/OrganizationInvitation';
import PasswordRecovery from '../../containers/Authentication/PasswordRecovery';
import SetNewPasswordHOC from '../../containers/Authentication/SetNewPassword';
import ChangeDeviceOwner from '../../containers/Devices/ChangeDeviceOwner';
import VerifyEmail from '../../containers/Authentication/VerifyEmail';
import Main from '../../containers/Main';
// import ActivateAccount from '../../containers/Users/ActivateAccount';
import Message from '../../containers/App/Message';
import { getAuthToken, clearAuthToken } from '../../utils/http-util';
import Loader from '../LoadingPane/Loader';
import {
    logoutSuccess,
    getLoggedInUserLoading,
    getLoggedInUserSuccess,
    getLoggedInUserError
} from '../../actions/authentication-actions';
import store from '../../store/get-store';
import { setConnectivityStatus } from '../../actions/app-actions';
import { getLoggedInUser } from '../../api/authentication-api';
import { parseErrorMessage } from '../../utils/global-message-util';
import WithAuthGuard from '../../containers/WithAuthenticationGuard';
import WithHelmet from '../../containers/WithHelmet';

import tunnelinMainImagePath from '../../assets/images/tunnelin_main.jpg';

const LoginWithHelmet = WithHelmet({
    title: 'Login',
    meta: [
        { name: 'og:url', content: '/login' },
    ],
    image: {
        src: tunnelinMainImagePath,
        width: 1200,
        height: 630,
        type: 'image/jpeg'
    },
    description: 'Tunnel In - Login to manage your devices and tunnels (SSH, VNC, RDP, or HTTPS). You can also configure a firewall for your tunnels with various rules.'
});

const SignupWithHelmet = WithHelmet({
    title: 'Sign up',
    meta: [
        { name: 'og:url', content: '/signup' },
    ],
    image: {
        src: tunnelinMainImagePath,
        width: 1200,
        height: 630,
        type: 'image/jpeg'
    },
    description: 'Tunnel In - Create an account, add devices and tunnels (SSH, VNC, RDP, or HTTPS). Create Organizations, invite users, and share devices, tunnels with them.'
});

const PasswordRecoveryWithHelmet = WithHelmet({
    title: 'Password Recovery',
    meta: [
        { name: 'og:url', content: '/passwordrecovery' },
    ],
    image: {
        src: tunnelinMainImagePath,
        width: 1200,
        height: 630,
        type: 'image/jpeg'
    },
    description: `Tunnel In - Forget password page. The password recovery link and instructions will be sent to the provided email address.`
});

const { dispatch } = store;

class App extends React.Component<
    {
        location: { search: string },
        history: { action: string, replace: (path: string) => void }
    },
    {
        afterDidMount: boolean,
        onLine: boolean,
        isAuthenticating: boolean
    }
    > {
    constructor(props) {
        super(props);
        this.state = {
            afterDidMount: false,
            onLine: navigator.onLine,
            isAuthenticating: false
        };
    }

    componentDidMount() {
        this.doInitApp();
        this.onLineInterval = setInterval(() => {
            const { onLine } = navigator;
            const { onLine: stateOnLine } = this.state;

            if (stateOnLine !== onLine) {
                this.setState({
                    onLine
                });
            }
        }, 1000);
        this.setState({
            afterDidMount: true
        });
    }

    componentWillUnmount() {
        clearInterval(this.onLineInterval);
    }

    // eslint-disable-next-line no-undef
    onLineInterval: IntervalID;

    doInitApp() {
        const authToken = getAuthToken();
        if (!authToken) {
            dispatch(logoutSuccess());
            this.setState({ isAuthenticating: false });
        } else {
            dispatch(setConnectivityStatus({ loading: true }));
            dispatch(getLoggedInUserLoading(true));

            this.setState({ isAuthenticating: true });
            getLoggedInUser().subscribe(
                user => {
                    const { data } = user;
                    // eslint-disable-next-line no-multi-assign
                    const _hsq = (window._hsq = window._hsq || []);
                    if (Array.isArray(_hsq)) {
                        _hsq.push([
                            'identify',
                            {
                                email: data.email
                            }
                        ]);
                        _hsq.push(['trackPageView']);
                        setTimeout(() => {
                            // eslint-disable-next-line no-unused-expressions
                            window.HubSpotConversations &&
                                window.HubSpotConversations.widget.refresh();
                        }, 1000);
                    }
                    dispatch(setConnectivityStatus({ loading: false }));
                    dispatch(getLoggedInUserSuccess(data));
                    dispatch(getLoggedInUserLoading(false));
                    this.setState({
                        isAuthenticating: false
                    });
                },
                error => {
                    dispatch(setConnectivityStatus({ loading: false }));
                    dispatch(getLoggedInUserError(parseErrorMessage(error)));
                    dispatch(getLoggedInUserLoading(false));
                    clearAuthToken();
                    this.setState({
                        isAuthenticating: false
                    });
                }
            );
        }
    }

    render() {
        const { afterDidMount, isAuthenticating } = this.state;

        if (!afterDidMount) {
            return null;
        }
        if (isAuthenticating) {
            return <Loader />;
        }

        return (
            <>
                <Switch>
                    <Route
                        path="/login"
                        component={WithAuthGuard({ authenticatedGuard: false })(
                            LoginWithHelmet(Login)
                        )}
                    />
                    <Route
                        path="/signup"
                        component={WithAuthGuard({ authenticatedGuard: false })(
                            SignupWithHelmet(Signup)
                        )}
                    />
                    <Route
                        path="/passwordrecovery"
                        component={WithAuthGuard({ authenticatedGuard: false })(
                            PasswordRecoveryWithHelmet(
                                PasswordRecovery
                            )
                        )}
                    />
                    <Route
                        path="/changedeviceowner"
                        component={WithHelmet({
                            title: 'Change Device Owner'
                        })(ChangeDeviceOwner)}
                    />
                    <Route
                        path="/verify-organization"
                        component={WithHelmet({
                            title: 'Organization Invitation'
                        })(OrganizationInvitation)}
                    />
                    <Route path="/verify-email" component={VerifyEmail} />
                    <Route
                        path="/setnewpassword"
                        component={WithAuthGuard({ authenticatedGuard: false })(
                            WithHelmet({
                                title: 'Set New Password'
                            })(SetNewPasswordHOC)
                        )}
                    />
                    <Route path="/" component={Main} />
                </Switch>
                <Message />
            </>
        );
    }
}

App.propTypes = {
    location: PropTypes.shape({
        search: PropTypes.string.isRequired
    }).isRequired,
    history: PropTypes.shape({
        action: PropTypes.string.isRequired,
        replace: PropTypes.func.isRequired
    }).isRequired
};

export default withRouter(DragDropContext(HTML5Backend)(withMaterial(App)));
