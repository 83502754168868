// @flow
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';

import styles from './styles';

class SplitButton extends Component<
    {
        classes: {
            buttonCls: string,
            buttonWrapper: string
        },
        label: any,
        color: string,
        disabled: boolean,
        size: string,
        variant: string,
        menuItems: any,
        linkTo: string
    },
    {
        anchorEl: any
    }
> {
    static propTypes = {
        label: PropTypes.node,
        color: PropTypes.string,
        linkTo: PropTypes.string,
        disabled: PropTypes.bool,
        onClick: PropTypes.func,
        size: PropTypes.string,
        variant: PropTypes.string,
        menuItems: PropTypes.array,
        classes: PropTypes.shape({
            buttonCls: PropTypes.string,
            buttonWrapper: PropTypes.string
        }).isRequired
    };

    static defaultProps = {
        label: '',
        linkTo: '',
        color: 'primary',
        disabled: false,
        size: 'small',
        variant: 'text',
        menuItems: []
    };

    state = {
        anchorEl: null
    };

    onArrowClick = event => {
        event.stopPropagation();
        event.preventDefault();
        this.setState({
            anchorEl: event.currentTarget
        });
    };

    handleClose = () => {
        this.setState({
            anchorEl: null
        });
    };

    render() {
        const {
            label,
            disabled,
            color,
            size,
            variant,
            menuItems,
            linkTo,
            onClick,
            classes: { buttonCls, buttonWrapper }
        } = this.props;
        const { anchorEl } = this.state;
        return (
            <div>
                <div className={buttonWrapper}>
                    <Button
                        color={color}
                        disabled={disabled}
                        className={buttonCls}
                        size={size}
                        variant={variant}
                        component={linkTo ? Link : undefined}
                        to={linkTo}
                        onClick={linkTo ? undefined : onClick}
                        target="_blank"
                    >
                        {label}
                        <ArrowDropDown
                            fontSize="small"
                            color={disabled ? 'disabled' : color}
                            disabled={disabled}
                            onClick={this.onArrowClick}
                        />
                    </Button>
                </div>
                <Menu
                    id="actions-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={this.handleClose}
                >
                    {menuItems.map(menuItem => menuItem)}
                </Menu>
            </div>
        );
    }
}

export default withStyles(styles)(SplitButton);
