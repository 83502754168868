import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import withMobileDialog from '@material-ui/core/withMobileDialog';

import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';

import {
    Hidden,
    FormControl,
    InputLabel,
    Input,
    FormHelperText,
    DialogContentText,
    Select,
    MenuItem,
    Checkbox,
    FormControlLabel,
    Tooltip
} from '@material-ui/core';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import HelpIcon from '@material-ui/icons/HelpOutline';
import {
    validateNameField,
    validateLocalPortField
} from '../../../utils/tunnel-validations';
import {
    addTunnel,
    getDeviceHttpTunnels,
    updateTunnel,
    deleteCustomTunnel,
    getTunnels
} from '../../../api/tunnel-api';
import {
    constructGlobalErrorMessage,
    parseErrorMessage
} from '../../../utils/global-message-util';
import { setGlobalMessageError } from '../../../actions/app-actions';
import { wrapActionCreators } from '../../../utils/container-util';
import HttpTunnelItem from './HttpTunnelItem';
import HttpTunnelItemMobile from './HttpTunnelItemMobile';
import { getLoggedInUser } from '../../../selectors/app-selectors';
import PasswordField from '../../PasswordField';

const styles = () => ({
    tunnelsWrapper: {
        flexDirection: 'column',
        display: 'flex',
        maxHeight: '400px',
        padding: '0 24px 24px 24px',
        overflowY: 'auto'
    },
    addFormWrapper: {
        padding: '24px'
    },
    dialog: {
        padding: '0',
        paddingTop: '0!important',
        minHeight: '200px'
    },
    dialogActions: {
        padding: '5px 24px'
    },
    noTunnelsYetDiv: {
        padding: '24px'
    },
    addAndStartButton: {
        margin: '0 4px'
    },
    addButton: {
        margin: '0 4px'
    },
    addTunnelForm: {
        display: 'flex',
        alignItems: 'baseline',
        padding: '5px 24px',
        '@media (max-width: 959px)': {
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        }
    },
    addTunnelHttp: {
        display: 'flex',
        alignItems: 'baseline',
        padding: '5px 24px',
        '@media (max-width: 959px)': {
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center'
        }
    },
    formControl: {
        margin: 5,
        flex: 1
    },
    httpUrlsWrapper: {
        marginLeft: 10,
        fontSize: 14,
        display: 'flex',
        flexDirection: 'column',
        color: '#9c27b0'
    }
});

const TunnelParamsModal = ({
    loggedInUser,
    fullScreen,
    close,
    deviceId,
    deviceName,
    classes,
    actions: { setGlobalMessageError }
}) => {
    const [tunnels, setTunnels] = useState([]);
    const [addMode, setAddMode] = useState(false);

    const [name, setName] = useState('');
    const [nameError, setNameError] = useState('');
    const [localPort, setLocalPort] = useState('');
    const [localPortError, setLocalPortError] = useState('');
    const [handleHTTP, setHandleHTTP] = useState(false);
    const [tunnelForEdit, setTunnelForEdit] = useState(null);
    const [doAddTunnel, setDoAddTunnel] = useState(false);
    const [doEditTunnel, setDoEditTunnel] = useState(false);
    const [tunnelForDelete, setTunnelForDelete] = useState(null);
    const [doDeleteTunnel, setDoDeleteTunnel] = useState(false);
    const [doRefresh, setDoRefresh] = useState('');
    const [allTunnels, setAllTunnels] = useState([]);

    const [tunnelPassword, setTunnelPassword] = useState(
        (((tunnelForEdit || {}).options || {}).auth || {}).password || ''
    );
    const [showPassword, setShowPassword] = useState(!!tunnelPassword);
    useEffect(() => {
        let canceled = false;
        getTunnels().subscribe(
            data => {
                if (data && data.data && data.data.tunnels && !canceled) {
                    setAllTunnels(data.data.tunnels);
                }
            },
            error => {
                setGlobalMessageError(
                    constructGlobalErrorMessage(parseErrorMessage(error))
                );
            }
        );
        return () => {
            canceled = true;
        };
    }, []);

    useEffect(
        () => {
            const val =
                (((tunnelForEdit || {}).options || {}).auth || {}).password ||
                '';
            setTunnelPassword(val);
            setShowPassword(!!val);
        },
        [tunnelForEdit]
    );

    const sortTunnels = tunnels =>
        tunnels.sort((t1, t2) => {
            if (
                (t1.favourite && t2.favourite) ||
                (!t1.favourite && !t2.favourite)
            ) {
                return 0;
            }
            if (!t1.favourite && t2.favourite) {
                return 1;
            }
            return -1;
        });

    useEffect(() => {
        let canceled = false;
        getDeviceHttpTunnels(deviceId).subscribe(
            data => {
                const tunnels = (data && data.data && data.data.tunnels) || [];
                if (!canceled) {
                    setTunnels(sortTunnels(tunnels));
                }
            },
            error => {
                setGlobalMessageError(
                    constructGlobalErrorMessage(parseErrorMessage(error))
                );
            }
        );
        return () => {
            canceled = true;
        };
    }, []);

    useEffect(
        () => {
            let canceled = false;
            if (doAddTunnel) {
                const data = {
                    name,
                    localPort,
                    handleHTTP,
                    deviceId,
                    type: 'http',
                    options: {
                        auth: {
                            password: showPassword ? tunnelPassword : undefined
                        },
                        firewall: {
                            allowConnectionsFrom: 'anywhere',
                            connectionsRateLimiting: false,
                            initializationTimeLimiting: 0,
                            autoClose: false
                        }
                    }
                };
                addTunnel(data).subscribe(
                    data => {
                        if (!canceled) {
                            setDoAddTunnel(false);
                            setAddMode(false);
                            const t = data && data.data && data.data.tunnel;
                            t.new = true;
                            if (t) {
                                setTunnels([t, ...tunnels]);
                            }
                        }
                    },
                    error => {
                        if (!canceled) {
                            setDoAddTunnel(false);
                        }
                        setGlobalMessageError(
                            constructGlobalErrorMessage(
                                parseErrorMessage(error)
                            )
                        );
                    }
                );
            }
            return () => {
                canceled = true;
            };
        },
        [doAddTunnel, name, localPort, handleHTTP]
    );

    useEffect(
        () => {
            let canceled = false;
            if (doEditTunnel && tunnelForEdit) {
                const data = {
                    name,
                    localPort,
                    handleHTTP,
                    deviceId,
                    options: {
                        auth: {
                            password: showPassword ? tunnelPassword : undefined
                        },
                        firewall: {
                            allowConnectionsFrom: 'anywhere',
                            connectionsRateLimiting: false,
                            initializationTimeLimiting: 0,
                            autoClose: false
                        }
                    },
                    favourite: tunnelForEdit.favourite
                };
                updateTunnel(tunnelForEdit._id, data).subscribe(
                    data => {
                        if (!canceled) {
                            const t = data && data.data;
                            setDoEditTunnel(false);
                            setTunnelForEdit(null);
                            setName('');
                            setLocalPort('');
                            setHandleHTTP(false);
                            if (t) {
                                const newTunnels = [...tunnels];
                                const oldTunnel = newTunnels.find(
                                    tun => tun._id === t._id
                                );
                                const i = newTunnels.indexOf(oldTunnel);
                                newTunnels.splice(i, 1, t);
                                setTunnels(newTunnels);
                                setDoRefresh(t._id);
                                setTimeout(() => {
                                    setDoRefresh('');
                                }, 0);
                            }
                        }
                    },
                    error => {
                        if (!canceled) {
                            setDoEditTunnel(false);
                        }
                        setGlobalMessageError(
                            constructGlobalErrorMessage(
                                parseErrorMessage(error)
                            )
                        );
                    }
                );
            }
            return () => {
                canceled = true;
            };
        },
        [doEditTunnel, name, localPort, handleHTTP, deviceId, tunnelForEdit]
    );

    useEffect(
        () => {
            let canceled = false;
            if (doDeleteTunnel && tunnelForDelete) {
                deleteCustomTunnel(tunnelForDelete._id).subscribe(
                    () => {
                        if (!canceled) {
                            const t = tunnelForDelete;
                            const newTunnels = [...tunnels];
                            const oldTunnel = newTunnels.find(
                                tun => tun._id === t._id
                            );
                            const i = newTunnels.indexOf(oldTunnel);
                            newTunnels.splice(i, 1);
                            setTunnels(newTunnels);
                            setDoDeleteTunnel(false);
                            setTunnelForDelete(null);
                        }
                    },
                    error => {
                        if (!canceled) {
                            setDoDeleteTunnel(false);
                        }
                        setGlobalMessageError(
                            constructGlobalErrorMessage(
                                parseErrorMessage(error)
                            )
                        );
                    }
                );
            }
            return () => {
                canceled = true;
            };
        },
        [doDeleteTunnel, tunnelForDelete]
    );

    const validateLocalPort = port => {
        let err = validateLocalPortField(port);
        if (!err) {
            const tunnelOnTheSamePort = tunnels.find(
                t =>
                    String(t.localPort) === String(port) &&
                    (tunnelForEdit ? t._id !== tunnelForEdit._id : true)
            );
            if (tunnelOnTheSamePort) {
                err = `Port "${port}" in use by "${
                    tunnelOnTheSamePort.name
                }" tunnel`;
            }
        }
        return err;
    };

    const validateName = name => {
        let err = validateNameField(name);
        if (!err) {
            const httpTunnels = allTunnels.filter(t => t.type === 'http');
            const tunnelWithTheSameName = httpTunnels.find(
                t =>
                    t.name === name &&
                    (tunnelForEdit ? t._id !== tunnelForEdit._id : true)
            );
            if (tunnelWithTheSameName) {
                err = `This url already assigned to other tunnel.`;
            }
        }
        return err;
    };

    const validateForm = () => {
        const nError = validateName(name);
        setNameError(nError);
        const lError = validateLocalPort(localPort);
        setLocalPortError(lError);

        return !nError && !lError;
    };

    const onAddEditBtnClick = () => {
        if (!tunnelForEdit && !addMode) {
            setAddMode(true);
            return;
        }
        if (addMode) {
            if (validateForm()) {
                setDoAddTunnel(true);
            }
            return;
        }
        if (tunnelForEdit) {
            if (
                validateForm(!nameError && !localPortError && name && localPort)
            ) {
                setDoEditTunnel(true);
            }
        }
    };
    const onNameChange = e => {
        const { value } = e.target;
        setName(value);
        setNameError(validateName(value));
    };
    const onLocalPortChange = e => {
        const { value } = e.target;
        setLocalPort(value);
        setLocalPortError(validateLocalPort(value));
    };

    const tunnelEdit = tunnel => {
        setName(tunnel.name);
        setLocalPort(tunnel.localPort);
        setHandleHTTP(tunnel.handleHTTP);
        setTunnelForEdit(tunnel);
    };

    const tunnelDelete = tunnel => {
        setTunnelForDelete(tunnel);
    };
    const handleFavouriteClick = tunnel => {
        updateTunnel(tunnel._id, {
            ...tunnel,
            favourite: !tunnel.favourite
        }).subscribe(
            data => {
                const t = data && data.data;
                if (t) {
                    const newTunnels = [...tunnels];
                    const oldTunnel = newTunnels.find(tun => tun._id === t._id);
                    const i = newTunnels.indexOf(oldTunnel);
                    newTunnels.splice(i, 1, t);
                    setTunnels(newTunnels);
                }
            },
            error => {
                setGlobalMessageError(
                    constructGlobalErrorMessage(parseErrorMessage(error))
                );
            }
        );
    };
    const renderTunnelItem = tunnel => {
        const { id } = tunnel;
        return (
            <React.Fragment key={id}>
                <Hidden smDown>
                    <HttpTunnelItem
                        doRefresh={doRefresh}
                        handleFavouriteClick={handleFavouriteClick}
                        tunnelDelete={tunnelDelete}
                        tunnelEdit={tunnelEdit}
                        tunnel={tunnel}
                    />
                </Hidden>
                <Hidden mdUp>
                    <HttpTunnelItemMobile
                        doRefresh={doRefresh}
                        handleFavouriteClick={handleFavouriteClick}
                        tunnelDelete={tunnelDelete}
                        tunnelEdit={tunnelEdit}
                        tunnel={tunnel}
                    />
                </Hidden>
            </React.Fragment>
        );
    };

    const closeConfirmDialog = () => {
        setTunnelForDelete(null);
    };

    return (
        <Dialog fullScreen={fullScreen} open onClose={close} maxWidth="md">
            <DialogTitle>HTTPS Tunnels of {deviceName}</DialogTitle>
            <DialogContent className={classes.dialog}>
                {tunnels ? (
                    <div>
                        {tunnels.length > 0 ? (
                            <div>
                                <div className={classes.tunnelsWrapper}>
                                    {tunnels.map(tunnel =>
                                        renderTunnelItem(tunnel)
                                    )}
                                </div>
                            </div>
                        ) : (
                            <div className={classes.noTunnelsYetDiv}>
                                No Tunnels yet
                            </div>
                        )}
                        {(addMode || tunnelForEdit) && (
                            <>
                                <div className={classes.addTunnelForm}>
                                    <FormControl
                                        required
                                        error={!!nameError}
                                        className={classes.formControl}
                                    >
                                        <InputLabel htmlFor="tunnel-name">
                                            Name
                                        </InputLabel>
                                        <Input
                                            id="tunnel-name"
                                            value={name}
                                            onChange={onNameChange}
                                        />
                                        {nameError && (
                                            <FormHelperText>
                                                {nameError}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                    <FormControl
                                        className={classes.formControl}
                                        required
                                        error={!!localPortError}
                                    >
                                        <InputLabel htmlFor="tunnel-local-port">
                                            Local Port
                                        </InputLabel>
                                        <Input
                                            type="number"
                                            id="tunnel-local-port"
                                            value={localPort}
                                            onChange={onLocalPortChange}
                                        />
                                        {localPortError && (
                                            <FormHelperText>
                                                {localPortError}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                    <FormControl
                                        className={classes.formControl}
                                    >
                                        <InputLabel htmlFor="type">
                                            Scheme
                                        </InputLabel>
                                        <Select
                                            fullWidth
                                            id="scheme"
                                            onChange={e =>
                                                setHandleHTTP(e.target.value)
                                            }
                                            autoWidth={true}
                                            value={handleHTTP}
                                            className={classes.formControl}
                                        >
                                            <MenuItem
                                                className={classes.menuItem}
                                                value={false}
                                            >
                                                https://
                                            </MenuItem>
                                            <MenuItem
                                                className={classes.menuItem}
                                                value={true}
                                            >
                                                https:// + http://
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                <div
                                    classes={classes.addTunnelHttp}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'flex-end',
                                        height: 64
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginRight: 10
                                        }}
                                    >
                                        <FormControlLabel
                                            style={{
                                                marginLeft: 15,
                                                marginRight: 5
                                            }}
                                            l
                                            control={
                                                <Checkbox
                                                    checked={showPassword}
                                                    onChange={e =>
                                                        setShowPassword(
                                                            e.target.checked
                                                        )
                                                    }
                                                    value="autoClose"
                                                />
                                            }
                                            label="Set tunnel password"
                                        />

                                        <Tooltip
                                            title=" Set a password to protect the HTTP tunnel."
                                            placement="bottom"
                                        >
                                            <HelpIcon
                                                fontSize="small"
                                                color="primary"
                                            />
                                        </Tooltip>
                                    </div>
                                    {showPassword && (
                                        <PasswordField
                                            autoComplete="off"
                                            showPassword={true}
                                            password={tunnelPassword}
                                            type="tunnelPass"
                                            onChange={setTunnelPassword}
                                        />
                                    )}
                                </div>
                                <div
                                    className={classes.leftSide}
                                    style={{
                                        height: 40,
                                        marginTop: 10,
                                        marginLeft: 30
                                    }}
                                >
                                    {name && !nameError ? (
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <div>URLs:</div>
                                            <div
                                                className={
                                                    classes.httpUrlsWrapper
                                                }
                                            >
                                                <i
                                                    style={{ marginBottom: 0 }}
                                                >{`https://${name}-${
                                                    loggedInUser.username
                                                }.p.tunnelin.com`}</i>
                                                {false && (
                                                    <i>
                                                        {`https://${
                                                            loggedInUser.username
                                                        }.p.tunnelin.com/${name}`}
                                                    </i>
                                                )}
                                            </div>
                                        </div>
                                    ) : (
                                        <div>
                                            Please type a valid name to generate
                                            urls
                                        </div>
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                ) : (
                    <div>
                        {
                            <div className={classes.tunnelsWrapper}>
                                Loading...
                            </div>
                        }
                    </div>
                )}
                {tunnelForDelete && (
                    <Dialog
                        fullScreen={fullScreen}
                        open
                        onClose={closeConfirmDialog}
                    >
                        <DialogTitle>Delete Tunnel</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Are you sure you want to delete tunnel?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => setDoDeleteTunnel(true)}
                            >
                                Delete
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={closeConfirmDialog}
                            >
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>
                )}
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onAddEditBtnClick}
                >
                    {addMode || tunnelForEdit ? 'Save' : 'Add Tunnel'}
                </Button>
                <Button variant="contained" color="primary" onClick={close}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

TunnelParamsModal.propTypes = {
    loggedInUser: PropTypes.object.isRequired,
    fullScreen: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    deviceId: PropTypes.string.isRequired,
    deviceName: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
    actions: PropTypes.shape({
        setGlobalMessageError: PropTypes.func.isRequired
    }).isRequired
};
const mapStateToProps = createStructuredSelector({
    loggedInUser: getLoggedInUser()
});
const mapDispatchToProps = wrapActionCreators({
    setGlobalMessageError
});

export default withMobileDialog()(
    withStyles(styles)(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(TunnelParamsModal)
    )
);
