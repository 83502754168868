// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { getLoggedInUser } from '../../../selectors/app-selectors';

import ProxyModal from '../../../components/Devices/Modal/ProxyModal';

const ProxyModalHOC = props => <ProxyModal {...props} />;
ProxyModalHOC.propTypes = {
    fullScreen: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    proxyConfig: PropTypes.object.isRequired
};
const mapStateToProps = createStructuredSelector({
    loggedInUser: getLoggedInUser()
});

export default connect(
    mapStateToProps,
    undefined
)(ProxyModalHOC);
