// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { Link } from '@material-ui/core';
import ClipboardCopy from '../../ClipboardCopy';
import { SSH_HELP_URL } from '../../../constants/api-constants';

const styles = () => ({
    paper: {
        width: '600px'
    },
    colorRed: {
        color: 'red'
    }
});

const SSHModal = props => {
    const {
        classes,
        fullScreen,
        close,
        sshConfig: {
            tunnel: {
                engine: { address },
                clientPort
            }
        }
    } = props;
    return (
        <Dialog fullScreen={fullScreen} open onClose={close}>
            <DialogContent>
                <Typography>
                    Your SSH tunnel to device will be available in few seconds.
                </Typography>
                <br />
                <Typography>
                    <strong> copy paste to command line </strong>(don&rsquo;t
                    forget to replace your username)
                </Typography>
                <Typography>
                    <ClipboardCopy
                        value={` ssh -p ${clientPort}  username@${address}`}
                        text={
                            <div>
                                ssh -p {clientPort}{' '}
                                <span className={classes.colorRed}>
                                    username
                                </span>
                                @{address}
                            </div>
                        }
                    />
                </Typography>
                <br />
                <Typography style={{ fontWeight: 'bold' }}>
                    For manual configuration use following:
                </Typography>
                <Typography>
                    Address: <ClipboardCopy text={address} value={address} />
                </Typography>
                <Typography>
                    Port: <ClipboardCopy text={clientPort} value={clientPort} />
                </Typography>
                <br />
                <Link
                    style={{ marginTop: 15 }}
                    rel="noopener noreferrer"
                    target="_blank"
                    href={SSH_HELP_URL}
                >
                    Click here for additional information about SSH
                    configuration.
                </Link>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="primary" onClick={close}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

SSHModal.propTypes = {
    classes: PropTypes.object.isRequired,
    fullScreen: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    sshConfig: PropTypes.object.isRequired
};
export default withMobileDialog()(withStyles(styles)(SSHModal));
