// @flow
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { sortBy } from 'lodash';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Toolbar from '@material-ui/core/Toolbar';

import Device from '../../containers/Devices/Card/Device';
import AddDeviceGroupHOC from '../../containers/Devices/Groups/Add';
import DeleteDeviceGroupHOC from '../../containers/Devices/Groups/Delete';
import UpdateDeviceGroupHOC from '../../containers/Devices/Groups/Update';
import ChangeDefaultGroupHOC from '../../containers/Devices/Groups/Default';
import TabContent from './Groups/TabContent';
import withDeviceDrop from './Groups/withDeviceDrop';

import OrderDevicesHOC from '../../containers/Devices/Order/index';
import OrderGroupsHOC from '../../containers/Devices/Groups/Order/index';
import { getLoggedInUserIdHash } from '../../utils/user-utils';

const DroppableTabContent = withDeviceDrop(TabContent);

const styles = theme => ({
    root: {
        display: 'flex',
        // display: 'grid',
        flexDirection: 'column',
        maxHeight: `calc(100vh - 64px)`,
        height: `calc(100vh - 64px)`,
        width: '100vw'
    },
    deviceWrapper: {
        minWidth: 340
    },
    listWrapper: {
        // flex: 1,
        maxHeight: 'calc(100vh - 128px)',
        display: 'grid',
        gridGap: '20px',
        gridTemplateColumns: `repeat(auto-fill, minmax(340px,1fr))`,
        boxSizing: 'border-box',
        padding: '20px 17px',
        overflowY: 'auto',
        overflowScrolling: 'touch',
        WebkitOverflowScrolling: 'touch'
    },
    flexGrow: {
        flex: '1 1 auto'
    },
    groupsFormControl: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        minWidth: 120,
        width: 120
    },
    addGroupBtn: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        cursor: 'pointer'
    },
    tabs: {
        flex: 1
    },
    toolbar: {
        width: '100vw',
        boxSizing: 'border-box'
    },
    dropDownMenuItem: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        maxWidth: 264
    }
});

class DeviceList extends React.Component<
    {
        classes: {
            root: string,
            deviceWrapper: string,
            toolbar: string,
            flexGrow: string,
            listWrapper: string,
            tabs: string,
            groupsFormControl: string,
            addGroupBtn: string,
            dropDownMenuItem: string
        },
        actions: {
            updateDevice: ({ _id: string, group: string }) => void,
            getDevices: () => void
        },
        statusesLoading: boolean,
        loggedInUser: any,
        devices: any[],
        groups: any[],
        devicesUpdating: string[]
    },
    {
        showOrderDevicesModal: boolean,
        showAddGroupModal: boolean,
        showOrderGroupsModal: boolean,
        showUpdateGroupModal: boolean,
        showDeleteGroupModal: boolean,
        showChangeDefaultGroupModal: boolean,
        selectedGroupValue: number,
        anchorEl: any,
        openModal: string
    }
> {
    static propTypes = {
        loggedInUser: PropTypes.object.isRequired,
        classes: PropTypes.object.isRequired,
        devices: PropTypes.array.isRequired,
        groups: PropTypes.array.isRequired,
        devicesUpdating: PropTypes.array.isRequired,
        statusesLoading: PropTypes.bool.isRequired
    };

    userIdHash = getLoggedInUserIdHash();

    constructor(props) {
        super(props);
        this.state = {
            showOrderDevicesModal: false,
            showAddGroupModal: false,
            showOrderGroupsModal: false,
            showUpdateGroupModal: false,
            showDeleteGroupModal: false,
            showChangeDefaultGroupModal: false,
            selectedGroupValue: this.getSelectedGroupValue() || 0,
            anchorEl: null,
            openModal: ''
        };
    }

    componentDidUpdate(prevProps) {
        const {
            groups: oldGroups,
            loggedInUser: oldLoggedInUser,
            devices,
            actions: { getDevices }
        } = prevProps;

        const { groups, loggedInUser } = this.props;

        const selectedGroupValue = this.getSelectedGroupValue();

        if (oldLoggedInUser.defaultGroup !== loggedInUser.defaultGroup) {
            this.setState({ showChangeDefaultGroupModal: false });
        }

        if (oldGroups.length > groups.length) {
            const diff = [];

            oldGroups.forEach(oldGroup => {
                if (groups.indexOf(oldGroup) < 0) {
                    diff.push(oldGroup);
                }
            });

            groups.forEach(group => {
                if (oldGroups.indexOf(group)) {
                    diff.push(group);
                }
            });

            const removedGroup = diff[0];
            // eslint-disable-next-line
            for (const device of devices) {
                if ((device.group && device.group._id) === removedGroup._id) {
                    getDevices();
                    break;
                }
            }

            this.setState({ selectedGroupValue, showDeleteGroupModal: false });
        } else if (oldGroups.length < groups.length) {
            this.setState({ showAddGroupModal: false });
        } else {
            for (let i = 0; i < groups.length; i += 1) {
                if (groups[i] && oldGroups[i] && groups[i] !== oldGroups[i]) {
                    this.setState({
                        selectedGroupValue,
                        showUpdateGroupModal: false
                    });
                    break;
                }
            }
        }
    }

    componentWillUnmount() {
        if (this.emitInterval) {
            clearInterval(this.emitInterval);
        }
    }

    getSelectedGroupValue = () => {
        const { groups, loggedInUser, devices } = this.props;
        const { defaultGroup } = loggedInUser;
        let selectedGroupValue = 0;
        // const storageActiveGroupId = localStorage.getItem('activeGroupId');
        const storageActiveGroupId = localStorage.getItem(
            `activeGroupId-${this.userIdHash}`
        );
        if (storageActiveGroupId) {
            const groupForSwitch = groups.find(
                group => group._id === storageActiveGroupId
            );
            if (groupForSwitch) {
                return groups.indexOf(groupForSwitch);
            }
        }

        if (defaultGroup) {
            const groupForSwitch = groups.find(
                group => group._id === defaultGroup
            );
            const defaultGroupIndex = groups.indexOf(groupForSwitch);
            if (devices.find(d => d.group === defaultGroup)) {
                selectedGroupValue = defaultGroupIndex;
                localStorage.setItem(
                    `activeGroupId-${this.userIdHash}`,
                    defaultGroup
                );
                return selectedGroupValue;
            }
        }

        for (let i = 0; i < groups.length; i += 1) {
            if (
                devices.find(
                    d =>
                        d.group === groups[i]._id ||
                        (d.group && d.group._id) === groups[i]._id
                )
            ) {
                selectedGroupValue = i;
                localStorage.setItem(
                    `activeGroupId-${this.userIdHash}`,
                    groups[i]._id
                );
                return selectedGroupValue;
            }
        }
        if (groups.length > 0)
            localStorage.setItem(
                `activeGroupId-${this.userIdHash}`,
                groups[0]._id
            );
        return selectedGroupValue;
    };

    onDeviceGroupAdd = () => {};

    // eslint-disable-next-line
    onDeviceDropToGroup = (device, group) => {
        const {
            actions: { updateDevice }
        } = this.props;
        const { _id } = device;
        updateDevice({ _id, group: group._id });
    };

    handleShowOrderDevicesModal = () => {
        this.setState({
            showOrderDevicesModal: true
        });
        this.handleMenuClose();
    };

    handleShowAddGroupModal = () => {
        this.setState({ openModal: 'showAddGroupModal' });
        this.handleMenuClose();
    };

    handleShowOrderGroupsModal = () => {
        this.setState({
            showOrderGroupsModal: true
        });
        this.handleMenuClose();
    };

    handleEditGroup = () => {
        this.setState({ showUpdateGroupModal: true });
        this.handleMenuClose();
    };

    handleDeleteGroup = () => {
        const { selectedGroupValue } = this.state;
        const { groups } = this.props;
        // eslint-disable-next-line
        const selectedGroup = groups[selectedGroupValue];
        // TODo...
        this.setState({ showDeleteGroupModal: true });
        this.handleMenuClose();
    };

    handleChangeDefaultGroup = () => {
        this.setState({ showChangeDefaultGroupModal: true });
        this.handleMenuClose();
    };

    openModal = () => {
        const { openModal } = this.state;
        if (openModal) {
            this.setState({ [openModal]: true });
        }
    };

    handleCloseOrderDevicesModal = () => {
        this.setState({ showOrderDevicesModal: false, openModal: '' });
    };

    handleCloseAddGroupModal = () => {
        this.setState({ showAddGroupModal: false, openModal: '' });
    };

    handleCloseOrderGroupsModal = () => {
        this.setState({ showOrderGroupsModal: false, openModal: '' });
    };

    handleCloseUpdateDeviceGroupModal = () => {
        this.setState({ showUpdateGroupModal: false, openModal: '' });
    };

    handleCloseDeleteDeviceGroupModal = () => {
        this.setState({ showDeleteGroupModal: false, openModal: '' });
    };

    handleCloseChangeDefaultGroupModal = () => {
        this.setState({ showChangeDefaultGroupModal: false, openModal: '' });
    };

    emitInterval = null;

    handleGroupTabChange = (event, value) => {
        const { groups } = this.props;
        this.setState({
            selectedGroupValue: value
        });
        localStorage.setItem(
            `activeGroupId-${this.userIdHash}`,
            groups[value]._id
        );
    };

    handleMenuClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleMenuClose = () => {
        this.setState({ anchorEl: null });
    };

    renderDevice = device => {
        const { classes, devicesUpdating, statusesLoading } = this.props;
        const { _id } = device;
        const isUpdating = devicesUpdating.indexOf(_id) !== -1;
        return (
            <div className={classes.deviceWrapper} key={_id}>
                <Device
                    statusesLoading={statusesLoading}
                    device={device}
                    isUpdating={isUpdating}
                    onDeviceDropToGroup={this.onDeviceDropToGroup}
                />
            </div>
        );
    };

    renderGroup = group => {
        const { name, id } = group;
        return (
            <MenuItem value={id} key={id}>
                {name}
            </MenuItem>
        );
    };

    determineAvailability = d => {
        const newDate = Date.now();
        const { socketData: { lastSeen } = {} } = d;
        if (!lastSeen) {
            return 'offlineCount';
        }
        const diffMs = newDate - lastSeen;
        const diffMins = Math.round(diffMs / (1000 * 60));
        if (diffMins > 10) {
            return 'offlineCount';
        }
        if (diffMins > 3) {
            return 'awayCount';
        }
        return 'onlineCount';
    };

    renderGroupTab = group => {
        const { _id } = group;
        const { devices } = this.props;
        const statusesCountInGroup = {
            onlineCount: 0,
            awayCount: 0,
            offlineCount: 0
        };

        let currentGroupDevices = devices;

        if (_id) {
            currentGroupDevices = devices.filter(
                device =>
                    (device.group && device.group._id) === _id ||
                    device.group === _id
            );
            currentGroupDevices = sortBy(currentGroupDevices, 'order');
        }
        if (_id === '-1' && currentGroupDevices.length === 0) {
            return null;
        }
        currentGroupDevices.forEach(d => {
            const st = this.determineAvailability(d);
            statusesCountInGroup[st] += 1;
        });

        return (
            <Tab
                label={
                    <DroppableTabContent
                        group={group}
                        statusesCountInGroup={statusesCountInGroup}
                    />
                }
                key={_id}
            />
        );
    };

    render() {
        const {
            showOrderDevicesModal,
            selectedGroupValue,
            showAddGroupModal,
            showOrderGroupsModal,
            showUpdateGroupModal,
            showDeleteGroupModal,
            showChangeDefaultGroupModal,
            anchorEl
        } = this.state;
        const { classes, devices, groups, loggedInUser } = this.props;

        const selectedGroup = groups[selectedGroupValue] || {};
        const { name, _id } = selectedGroup;

        const { defaultGroup } = loggedInUser;

        const selectedGroupIsDefault = defaultGroup === _id;

        let filteredDevices = devices;

        if (_id) {
            filteredDevices = devices.filter(
                device =>
                    (device.group && device.group._id) === _id ||
                    device.group === _id
            );
            filteredDevices = sortBy(filteredDevices, 'order');
        }

        return (
            <div
                className={classNames(classes.root, {
                    devicesMain: true
                })}
            >
                <Toolbar className={classes.toolbar}>
                    <Tabs
                        className={classes.tabs}
                        value={selectedGroupValue}
                        onChange={this.handleGroupTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        {groups.map(group => this.renderGroupTab(group))}
                    </Tabs>
                    <IconButton
                        aria-label="More"
                        aria-owns={anchorEl ? 'device-list-options-menu' : null}
                        aria-haspopup="true"
                        onClick={this.handleMenuClick}
                    >
                        <MoreVertIcon />
                    </IconButton>
                    <Menu
                        id="device-list-options-menu"
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={this.handleMenuClose}
                        onExited={this.openModal}
                    >
                        {/* <MenuItem onClick={this.handleShowAddDeviceModal}> */}
                        {/* Add Device */}
                        {/* </MenuItem> */}
                        <MenuItem
                            onClick={this.handleShowOrderDevicesModal}
                            disabled={filteredDevices.length < 2}
                        >
                            <div className={classes.dropDownMenuItem}>
                                Order Devices
                            </div>
                        </MenuItem>
                        <MenuItem onClick={this.handleShowAddGroupModal}>
                            <div className={classes.dropDownMenuItem}>
                                Add Group
                            </div>
                        </MenuItem>
                        <MenuItem onClick={this.handleShowOrderGroupsModal}>
                            <div className={classes.dropDownMenuItem}>
                                Order Groups
                            </div>
                        </MenuItem>
                        <MenuItem
                            onClick={this.handleDeleteGroup}
                            disabled={selectedGroupIsDefault}
                        >
                            <div className={classes.dropDownMenuItem}>
                                Delete {name}
                            </div>
                        </MenuItem>
                        <MenuItem onClick={this.handleEditGroup}>
                            <div className={classes.dropDownMenuItem}>
                                Edit {name}
                            </div>
                        </MenuItem>
                        <MenuItem
                            onClick={this.handleChangeDefaultGroup}
                            disabled={selectedGroupIsDefault}
                        >
                            <div className={classes.dropDownMenuItem}>
                                Make {name} as Default
                            </div>
                        </MenuItem>
                    </Menu>
                </Toolbar>
                <div className={classes.listWrapper}>
                    {filteredDevices.map(device => this.renderDevice(device))}
                </div>
                {showOrderDevicesModal && (
                    <OrderDevicesHOC
                        devices={filteredDevices}
                        close={this.handleCloseOrderDevicesModal}
                    />
                )}
                {showAddGroupModal && (
                    <AddDeviceGroupHOC
                        close={this.handleCloseAddGroupModal}
                        add={this.onDeviceGroupAdd}
                        groupsLength={groups.length}
                    />
                )}
                {showOrderGroupsModal && (
                    <OrderGroupsHOC
                        groups={groups}
                        close={this.handleCloseOrderGroupsModal}
                    />
                )}
                {showUpdateGroupModal && (
                    <UpdateDeviceGroupHOC
                        close={this.handleCloseUpdateDeviceGroupModal}
                        group={selectedGroup}
                    />
                )}
                {showDeleteGroupModal && (
                    <DeleteDeviceGroupHOC
                        close={this.handleCloseDeleteDeviceGroupModal}
                        group={selectedGroup}
                    />
                )}
                {showChangeDefaultGroupModal && (
                    <ChangeDefaultGroupHOC
                        close={this.handleCloseChangeDefaultGroupModal}
                        group={selectedGroup}
                    />
                )}
            </div>
        );
    }
}

export default withStyles(styles)(DeviceList);
