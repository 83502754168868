// @flow

export const SET_CONNECTIVITY_STATUS = 'SET_CONNECTIVITY_STATUS';
export const SET_GLOBAL_LOADER = 'SET_GLOBAL_LOADER';
export const SET_GLOBAL_MESSAGE_ERROR = 'SET_GLOBAL_MESSAGE_ERROR';
export const SET_GLOBAL_MESSAGE_SUCCESS = 'SET_GLOBAL_MESSAGE_SUCCESS';
export const SET_GLOBAL_MESSAGE_COOKIES = 'SET_GLOBAL_MESSAGE_COOKIES';
export const INIT_APP = 'INIT_APP';

// Login and Logout
export const LOGIN = 'LOGIN_LOADING';
export const LOGIN_LOADING = 'LOGIN_LOADING';

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';

export const CHANGE_ORGANIZATION = 'CHANGE_ORGANIZATION';

export const LOGOUT_LOADING = 'LOGOUT_LOADING';

export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export const GET_LOGGED_IN_USER = 'GET_LOGGED_IN_USER';
export const GET_LOGGED_IN_USER_LOADING = 'GET_LOGGED_IN_USER_LOADING';
export const GET_LOGGED_IN_USER_SUCCESS = 'GET_LOGGED_IN_USER_SUCCESS';
export const GET_LOGGED_IN_USER_ERROR = 'GET_LOGGED_IN_USER_ERROR';

export const UPDATE_CURRENT_USER = 'UPDATE_CURRENT_USER';
export const UPDATE_CURRENT_USER_LOADING = 'UPDATE_CURRENT_USER_LOADING';
export const UPDATE_CURRENT_USER_SUCCESS = 'UPDATE_CURRENT_USER_SUCCESS';

export const CHANGE_CURRENT_USER_PASSWORD = 'CHANGE_CURRENT_USER_PASSWORD';
export const CHANGE_CURRENT_USER_PASSWORD_LOADING =
    'CHANGE_CURRENT_USER_PASSWORD_LOADING';
export const CHANGE_CURRENT_USER_PASSWORD_SUCCESS =
    'CHANGE_CURRENT_USER_PASSWORD_SUCCESS';
export const TOGGLE_TWO_FACTOR_AUTH = 'TOGGLE_TWO_FACTOR_AUTH';
export const TOGGLE_TWO_FACTOR_AUTH_LOADING = 'TOGGLE_TWO_FACTOR_AUTH_LOADING';
export const REGENERATE_TWO_FACTOR_QR = 'REGENERATE_TWO_FACTOR_QR';
export const REGENERATE_TWO_FACTOR_QR_LOADING =
    'REGENERATE_TWO_FACTOR_QR_LOADING';
export const INSUFFICIENTLY_AUTHENTICATION_DETAILS =
    'INSUFFICIENTLY_AUTHENTICATION_DETAILS';

// Users
export const USERS_LOADING = 'USERS_LOADING';

export const USERS_SUCCESS = 'USERS_SUCCESS';

export const USERS_ERROR = 'USERS_ERROR';

export const SIGNUP = 'SIGNUP';
export const SIGNUP_ACTIVATE_ORG_USER = 'SIGNUP_ACTIVATE_ORG_USER';
export const SIGNUP_LOADING = 'SIGNUP_LOADING';

export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';

export const MAKE_GROUP_AS_DEFAULT = 'MAKE_GROUP_AS_DEFAULT';
export const MAKE_GROUP_AS_DEFAULT_LOADING = 'MAKE_GROUP_AS_DEFAULT_LOADING';
export const MAKE_GROUP_AS_DEFAULT_SUCCESS = 'MAKE_GROUP_AS_DEFAULT_SUCCESS';

export const SEND_ACTIVATION_EMAIL = 'SEND_ACTIVATION_EMAIL';
export const SEND_ACTIVATION_EMAIL_LOADING = 'SEND_ACTIVATION_EMAIL_LOADING';

// Devices

export const GET_DEVICES = 'GET_DEVICES';
export const GET_DEVICES_LOADING = 'GET_DEVICES_LOADING';
export const GET_DEVICES_SUCCESS = 'GET_DEVICES_SUCCESS';
export const GET_DEVICES_ERROR = 'GET_DEVICES_ERROR';

export const ADD_DEVICE = 'ADD_DEVICE';
export const ADD_DEVICE_LOADING = 'ADD_DEVICE_LOADING';
export const ADD_DEVICE_SUCCESS = 'ADD_DEVICE_SUCCESS';

export const UPDATE_DEVICE = 'UPDATE_DEVICE';
export const UPDATE_DEVICE_LOADING = 'UPDATE_DEVICE_LOADING';
export const UPDATE_DEVICE_SUCCESS = 'UPDATE_DEVICE_SUCCESS';

export const DELETE_DEVICE = 'DELETE_DEVICE';
export const DELETE_DEVICE_LOADING = 'DELETE_DEVICE_LOADING';
export const DELETE_DEVICE_SUCCESS = 'DELETE_DEVICE_SUCCESS';

export const GET_DEVICES_STATUSES = 'GET_DEVICES_STATUSES';
export const GET_DEVICES_STATUSES_LOADING = 'GET_DEVICES_STATUSES_LOADING';
export const GET_DEVICES_STATUSES_SUCCESS = 'GET_DEVICES_STATUSES_SUCCESS';

export const DEVICE_SOCKET_MESSAGE = 'SOCKET_MESSAGE';

// Groups

export const GET_GROUPS = 'GET_GROUPS';
export const GET_GROUPS_LOADING = 'GET_GROUPS_LOADING';
export const GET_GROUPS_SUCCESS = 'GET_GROUPS_SUCCESS';

export const UPDATE_GROUPS_ORDERING = 'UPDATE_GROUPS_ORDERING';
export const UPDATE_GROUPS_ORDERING_LOADING = 'UPDATE_GROUPS_ORDERING_LOADING';
export const UPDATE_GROUPS_ORDERING_SUCCESS = 'UPDATE_GROUPS_ORDERING_SUCCESS';

export const ADD_GROUP = 'ADD_GROUP';
export const ADD_GROUP_LOADING = 'ADD_GROUP_LOADING';
export const ADD_GROUP_SUCCESS = 'ADD_GROUP_SUCCESS';

export const DELETE_GROUP = 'DELETE_GROUP';
export const DELETE_GROUP_LOADING = 'DELETE_GROUP_LOADING';
export const DELETE_GROUP_SUCCESS = 'DELETE_GROUP_SUCCESS';

export const UPDATE_GROUP = 'UPDATE_GROUP';
export const UPDATE_GROUP_LOADING = 'UPDATE_GROUP_LOADING';
export const UPDATE_GROUP_SUCCESS = 'UPDATE_GROUP_SUCCESS';

// User Devices
export const CLEAR_DEVICE_USERS = 'CLEAR_DEVICE_USERS';

export const GET_DEVICE_USERS = 'GET_DEVICE_USERS';
export const GET_DEVICE_USERS_LOADING = 'GET_DEVICE_USERS_LOADING';
export const GET_DEVICE_USERS_SUCCESS = 'GET_DEVICE_USERS_SUCCESS';

export const UPDATE_DEVICES_ORDERING = 'UPDATE_DEVICES_ORDERING';
export const UPDATE_DEVICES_ORDERING_LOADING =
    'UPDATE_DEVICES_ORDERING_LOADING';
export const UPDATE_DEVICES_ORDERING_SUCCESS =
    'UPDATE_DEVICES_ORDERING_SUCCESS';
export const UPDATE_DEVICES_ORDERING_ERROR = 'UPDATE_DEVICES_ORDERING_ERROR';

export const ADD_USER_TO_DEVICE = 'ADD_USER_TO_DEVICE';
export const ADD_USER_TO_DEVICE_LOADING = 'ADD_USER_TO_DEVICE_LOADING';
export const ADD_USER_TO_DEVICE_SUCCESS = 'ADD_USER_TO_DEVICE_SUCCESS';
export const ADD_USER_TO_DEVICE_ERROR = 'ADD_USER_TO_DEVICE_ERROR';

export const ADD_OR_REMOVE_USERS_TO_DEVICE = 'ADD_OR_REMOVE_USERS_TO_DEVICE';
export const ADD_OR_REMOVE_USERS_TO_DEVICE_LOADING =
    'ADD_OR_REMOVE_USERS_TO_DEVICE_LOADING';
export const ADD_OR_REMOVE_USERS_TO_DEVICE_SUCCESS =
    'ADD_OR_REMOVE_USERS_TO_DEVICE_SUCCESS';

export const UPDATE_USER_TO_DEVICE_ACCESS = 'UPDATE_USER_TO_DEVICE';
export const UPDATE_USER_TO_DEVICE_ACCESS_LOADING =
    'UPDATE_USER_TO_DEVICE_ACCESS_LOADING';
export const UPDATE_USER_TO_DEVICE_ACCESS_SUCCESS =
    'UPDATE_USER_TO_DEVICE_ACCESS_SUCCESS';
export const UPDATE_USER_TO_DEVICE_ACCESS_ERROR =
    'UPDATE_USER_TO_DEVICE_ACCESS_ERROR';

export const REMOVE_USER_FROM_DEVICE = 'REMOVE_USER_FROM_DEVICE';
export const REMOVE_USER_FROM_DEVICE_LOADING = 'DELETE_USER_DEVICE_LOADING';
export const REMOVE_USER_FROM_DEVICE_SUCCESS = 'DELETE_USER_DEVICE_SUCCESS';
export const REMOVE_USER_FROM_DEVICE_ERROR = 'DELETE_USER_DEVICE_ERROR';

export const REMOVE_CURRENT_USER_FROM_DEVICE =
    'REMOVE_CURRENT_USER_FROM_DEVICE';
export const REMOVE_CURRENT_USER_FROM_DEVICE_LOADING =
    'REMOVE_CURRENT_USER_FROM_DEVICE_LOADING';
export const REMOVE_CURRENT_USER_FROM_DEVICE_SUCCESS =
    'REMOVE_CURRENT_USER_FROM_DEVICE_SUCCESS';

// Tunnels
export const UPDATE_TUNNEL_SEARCH = 'UPDATE_TUNNEL_SEARCH';
export const RESET_ALL_TUNNEL_FILTERS = 'RESET_ALL_TUNNEL_FILTERS';
export const UPDATE_TUNNEL_UI_FILTERS = 'UPDATE_TUNNEL_UI_FILTERS';
export const UPDATE_TUNNEL_FILTER = 'UPDATE_TUNNEL_FILTER';

export const VERIFY_EMAIL_SUCCESS = 'VERIFY_EMAIL_SUCCESS';

export const GET_TUNNEL_STATUSES = 'GET_TUNNEL_STATUSES';
export const GET_TUNNEL_STATUSES_SUCCES = 'GET_TUNNEL_STATUSES_SUCCES';
