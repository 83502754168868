// @flow

import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import SaveIcon from '@material-ui/icons/Save';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CancelIcon from '@material-ui/icons/Cancel';
import CircleIcon from '@material-ui/icons/FiberManualRecord';
import FormControl from '@material-ui/core/FormControl';
import Tooltip from '@material-ui/core/Tooltip';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
    openVNCTunnel,
    openSSHTunnel,
    openRDPTunnel,
    openProxyTunnel
} from '../../../api/tunnel-api';
import DeleteDevice from '../Delete';
import SSHModal from '../Modal/SSHModal';
import ProxyModal from '../../../containers/Devices/Modal/ProxyModal';
import VNCModal from '../Modal/VNCModal';
import RDPModal from '../Modal/RDPModal';
import ShareModal from '../../../containers/Devices/Modal/ShareModal';
import ChangeDeviceOwnerModal from '../../../containers/Devices/Modal/ChangeDeviceOwnerModal';
import SplitButton from '../../SplitButton';
import {
    constructGlobalErrorMessage,
    parseErrorMessage
} from '../../../utils/global-message-util';
import TunnelParamsModal from '../Modal/TunnelParamsModal';
import { IotIcon, LinuxIcon, MacIcon, WindowsIcon } from '../../../icons';

const styles = () => ({
    root: {
        width: 340,
        minWidth: 340,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    cardHeader: {
        padding: '16px 21px 0 16px',
        '&>div:first-child': {
            overflow: 'hidden'
        }
    },
    cardContent: {
        padding: '0 16px 0 16px'
    },
    isDragging: {
        opacity: 0.5
    },
    titleWrapper: {
        display: 'flex',
        alignItems: 'center',
        overflow: 'hidden'
    },
    formControl: {
        marginBottom: 8,
        flex: 1,
        marginRight: 5
    },
    title: {
        marginBottom: 10,
        marginTop: 6,
        marginLeft: -4,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        fontSize: 22,
        fontWeight: '500',
        flex: 1
    },
    section: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    },
    button: {
        marginLeft: 0
    },
    editIcon: {
        marginTop: 6,
        cursor: 'pointer'
    },
    savelIcon: {
        marginTop: 6,
        cursor: 'pointer'
    },
    cancelIcon: {
        marginTop: 6,
        cursor: 'pointer'
    },
    lastSeenIcon: {
        marginRight: 5,
        marginLeft: -6,
        marginTop: -5,
        marginBottom: -5,
        fontSize: 14
    },
    colorGreen: {
        fill: '#61bd4f'
    },
    colorYellow: {
        fill: '#f2d600'
    },
    colorRed: {
        fill: '#eb5a46'
    },
    textColorRed: {
        color: 'red'
    },
    link: {
        color: 'inherit',
        textDecoration: 'none'
    },
    centerContent: {
        padding: '8px 16px'
        // justifyContent: 'center',
    }
});

type DeviceProps = {
    actions: {
        updateDevice: ({ _id: string, name: string }) => void,
        deleteDevice: (_id: string) => void,
        removeCurrentUserFromDevice: (deviceId: string) => void,
        setGlobalMessageError: (message: any) => void
    },
    isUpdating: boolean,
    statusesLoading: boolean,
    classes: {
        isDragging: string,
        root: string,
        cardHeader: string,
        cardContent: string,
        titleWrapper: string,
        title: string,
        section: string,
        formControl: string,
        button: string,
        editIcon: string,
        savelIcon: string,
        cancelIcon: string,
        lastSeenIcon: string,
        colorRed: string,
        colorYellow: string,
        colorGreen: string,
        textColorRed: string,
        link: string,
        centerContent: string
    },
    device: any,
    isDragging: boolean
};

class Device extends React.Component<
    DeviceProps,
    {
        cardActionsAnchorEl: any,
        editMode: boolean,
        name: string,
        modal: string,
        sshConfig: any,
        proxyConfig: any,
        vncConfig: any,
        rdpConfig: any
    }
> {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        device: PropTypes.object.isRequired,
        isUpdating: PropTypes.bool.isRequired,
        actions: PropTypes.shape({
            updateDevice: PropTypes.func.isRequired,
            deleteDevice: PropTypes.func.isRequired,
            removeCurrentUserFromDevice: PropTypes.func.isRequired,
            setGlobalMessageError: PropTypes.func.isRequired
        }).isRequired,
        isDragging: PropTypes.bool.isRequired,
        statusesLoading: PropTypes.bool.isRequired
    };

    reRenderInterval = null;

    constructor(props) {
        super(props);
        this.state = {
            cardActionsAnchorEl: null,
            editMode: false,
            modal: '',
            sshConfig: {},
            vncConfig: {},
            rdpConfig: {},
            proxyConfig: {},
            name: props.device.name,
            ipAddressTooltipTitle: 'Click to copy IP address',
            ipAddressTooltipOpenState: false,
            copiedTime: null
        };
    }

    componentDidMount() {
        this.reRenderInterval = setInterval(this.onReRender, 20 * 1000);
    }

    componentDidUpdate(prevProps) {
        const {
            device: { name: newName }
        } = this.props;
        const {
            device: { name: oldName }
        } = prevProps;
        if (oldName !== newName) {
            this.setState({ name: newName, editMode: false });
        }
    }

    componentWillUnmount() {
        if (this.reRenderInterval) {
            clearInterval(this.reRenderInterval);
        }
    }

    onReRender = () => {
        this.forceUpdate();
    };

    onProxyClick = () => {
        const {
            device,
            actions: { setGlobalMessageError }
        } = this.props;
        const { _id } = device;
        return openProxyTunnel(_id, device).subscribe(
            data => {
                if (data) {
                    this.setState({ proxyConfig: data.data, modal: 'proxy' });
                }
            },
            error => {
                setGlobalMessageError(
                    constructGlobalErrorMessage(parseErrorMessage(error))
                );
            }
        );
    };

    onHTTPClick = () => {
        this.setState({ modal: 'custom-tunnel' });
    };

    onShareDeviceClick = () => {
        this.handleMenuClose();
        this.setState({ modal: 'share' });
    };

    onEditTitleClick = () => {
        const {
            device: { shared }
        } = this.props;
        if (!shared) {
            this.handleMenuClose();
            this.setState({ editMode: true });
        }
    };

    onChangeDeviceOwnerClick = () => {
        this.handleMenuClose();
        this.setState({ modal: 'changeOwner' });
    };

    onDeleteDeviceClick = () => {
        this.handleMenuClose();
        this.setState({ modal: 'delete' });
    };

    onDeleteDevice = () => {
        const {
            device: { _id },
            actions: { deleteDevice }
        } = this.props;
        deleteDevice(_id);
        this.onCloseDeleteModal();
    };

    onUnshareDevice = () => {
        const {
            device: { _id },
            actions: { removeCurrentUserFromDevice }
        } = this.props;
        removeCurrentUserFromDevice(_id);
        this.onCloseDeleteModal();
    };

    onCloseModal = () => {
        this.setState({ modal: '' });
    };

    onCloseDeleteModal = () => {
        this.setState({ modal: '' });
    };

    onCloseSSHModal = () => {
        this.setState({ sshConfig: {}, modal: '' });
    };

    onCloseProxyModal = () => {
        this.setState({ proxyConfig: {}, modal: '' });
    };

    onCloseVNCModal = () => {
        this.setState({ vncConfig: {}, modal: '' });
    };

    onCloseRDPModal = () => {
        this.setState({ rdpConfig: {}, modal: '' });
    };

    onCancelUpdateClick = () => {
        const {
            device: { name: oldName },
            isUpdating
        } = this.props;
        if (!isUpdating) {
            this.setState({
                name: oldName,
                editMode: false
            });
        }
    };

    onKeyDown = event => {
        switch (event.keyCode) {
            case 13: {
                this.handleSave();
                break;
            }
            case 27: {
                this.setState({ editMode: false });
                break;
            }
            default:
                break;
        }
    };

    handleNameChange = event => {
        this.setState({ name: event.currentTarget.value });
    };

    handleSave = () => {
        const {
            device: { _id, name: oldName },
            actions: { updateDevice },
            isUpdating
        } = this.props;
        const { name } = this.state;
        if (name !== oldName) {
            if (!isUpdating) {
                updateDevice({ _id, name });
            }
        } else {
            this.setState({ editMode: false });
        }
    };

    determineAvailability = () => {
        const newDate = Date.now();
        const {
            device: { socketData: { lastSeen } = {} }
        } = this.props;

        const diffMs = newDate - lastSeen;
        const diffMins = Math.round(diffMs / (1000 * 60));
        if (diffMins > 10) {
            return 'offline';
        }
        if (diffMins > 3) {
            return 'away';
        }
        return 'online';
    };

    handleMenuClick = event => {
        this.setState({ cardActionsAnchorEl: event.currentTarget });
    };

    handleMenuClose = () => {
        this.setState({ cardActionsAnchorEl: null });
    };

    onOpenSSHConfigurationClick = () => {
        const {
            device,
            actions: { setGlobalMessageError }
        } = this.props;
        const { _id } = device;
        return openSSHTunnel(_id, device).subscribe(
            data => {
                if (data) {
                    this.setState({ sshConfig: data.data, modal: 'ssh' });
                }
            },
            error => {
                setGlobalMessageError(
                    constructGlobalErrorMessage(parseErrorMessage(error))
                );
            }
        );
    };

    getSSHButtonLabel = () => {
        const {
            classes,
            device: { _id, name }
        } = this.props;
        return (
            <Link
                className={classes.link}
                href={`/terminal/ssh/${_id}/${name}`}
                to={`/terminal/ssh/${_id}/${name}`}
                target="_blank"
            >
                SSH
            </Link>
        );
    };

    getSSHLinkTo = () => {
        const {
            device: { _id, name }
        } = this.props;
        return `/terminal/ssh/${_id}/${name}`;
    };

    getSSHMenuItems = () => {
        const {
            classes,
            device: { _id, name }
        } = this.props;
        return [
            <MenuItem key="1" onClick={this.onOpenSSHConfigurationClick}>
                See configurations
            </MenuItem>,
            <MenuItem key="2">
                <Link
                    className={classes.link}
                    href={`/terminal/ssh/${_id}/${name}`}
                    to={`/terminal/ssh/${_id}/${name}`}
                    target="_blank"
                >
                    Try in browser
                </Link>
            </MenuItem>
        ];
    };

    onOpenVNCConfigurationClick = () => {
        const {
            device,
            actions: { setGlobalMessageError }
        } = this.props;
        const { _id } = device;
        return openVNCTunnel(_id, device).subscribe(
            data => {
                if (data) {
                    this.setState({ vncConfig: data.data, modal: 'vnc' });
                }
            },
            error => {
                setGlobalMessageError(
                    constructGlobalErrorMessage(parseErrorMessage(error))
                );
            }
        );
    };

    onOpenRDPConfigurationClick = () => {
        const {
            device,
            actions: { setGlobalMessageError }
        } = this.props;
        const { _id } = device;
        return openRDPTunnel(_id, device).subscribe(
            data => {
                if (data) {
                    this.setState({ rdpConfig: data.data, modal: 'rdp' });
                }
            },
            error => {
                setGlobalMessageError(
                    constructGlobalErrorMessage(parseErrorMessage(error))
                );
            }
        );
    };

    getVNCButtonLabel = () => {
        const {
            classes,
            device: { _id, name }
        } = this.props;
        return (
            <Link
                className={classes.link}
                href={`/terminal/vnc/${_id}/${name}`}
                to={`/terminal/vnc/${_id}/${name}`}
                target="_blank"
            >
                VNC
            </Link>
        );
    };

    getVNCLinkTo = () => {
        const {
            device: { _id, name }
        } = this.props;
        return `/terminal/vnc/${_id}/${name}`;
    };

    getRDPLinkTo = () => {
        const {
            device: { _id, name }
        } = this.props;
        return `/terminal/rdp/${_id}/${name}`;
    };

    getVNCMenuItems = () => {
        const {
            classes,
            device: { _id, name }
        } = this.props;
        return [
            <MenuItem key="1" onClick={this.onOpenVNCConfigurationClick}>
                See configurations
            </MenuItem>,
            <MenuItem key="2">
                <Link
                    className={classes.link}
                    href={`/terminal/vnc/${_id}/${name}`}
                    to={`/terminal/vnc/${_id}/${name}`}
                    target="_blank"
                >
                    Try in browser
                </Link>
            </MenuItem>
        ];
    };

    getRDPMenuItems = () => {
        const {
            classes,
            device: { _id, name }
        } = this.props;
        return [
            <MenuItem key="1" onClick={this.onOpenRDPConfigurationClick}>
                See configurations
            </MenuItem>,
            <MenuItem key="2">
                <Link
                    className={classes.link}
                    href={`/terminal/rdp/${_id}/${name}`}
                    to={`/terminal/rdp/${_id}/${name}`}
                    target="_blank"
                >
                    Try in browser
                </Link>
            </MenuItem>
        ];
    };

    onIpAddressTooltipClose = () => {
        this.setState({
            ipAddressTooltipOpenState: false
        });
    };

    onIpAddressTooltipOpen = () => {
        const { copiedTime } = this.state;
        let { ipAddressTooltipTitle } = this.state;
        if (Date.now() - copiedTime > 3 * 1000) {
            ipAddressTooltipTitle = 'Click to copy IP address.';
        }
        this.setState({
            ipAddressTooltipOpenState: true,
            ipAddressTooltipTitle
        });
    };

    onIpAddressCopied = () => {
        this.setState({
            copiedTime: Date.now(),
            ipAddressTooltipTitle: 'Ip address copied.'
        });
    };

    render() {
        const {
            cardActionsAnchorEl,
            editMode,
            name,
            modal,
            sshConfig,
            proxyConfig,
            vncConfig,
            rdpConfig,
            ipAddressTooltipTitle,
            ipAddressTooltipOpenState
        } = this.state;
        const { classes, device, isDragging, statusesLoading } = this.props;
        const {
            socketData: { loadavg, ip, users, time, lastSeen } = {}
        } = device;
        let { socketData: { uptime } = {} } = device;
        if (!Number.isNaN(Number(uptime))) {
            const mins = Math.floor((uptime % (60 * 60)) / 60);
            const minsZero = mins < 10 ? `0${mins}` : `${mins}`;
            const hours = Math.floor((uptime % (60 * 60 * 24)) / (60 * 60));
            const days = Math.floor(uptime / (60 * 60 * 24));
            const daysStr =
                days > 1 ? `${days} days, ` : days === 1 ? '1 day, ' : '';
            const hoursStr = hours > 0 ? `${hours}:` : '';
            const minsStr = hours === 0 ? `${mins} min` : minsZero;
            uptime = (daysStr + hoursStr + minsStr).replace(', 0 min', '');
        }
        let { platform } = device;
        const platformMap = {
            win32: 'windows',
            darwin: 'mac',
            '': 'linux',
            aix: 'linux',
            freebsd: 'linux',
            linux: 'linux',
            openbsd: 'linux',
            sunos: 'linux',
            IOT: 'iot'
        };
        platform = platformMap[platform || 'linux'];

        const { shared } = device;

        let lastSeenIndicatorClassName = classes.colorRed;
        let disableButton = true;
        let lastSeenIndicatorTooltipMessage = statusesLoading
            ? 'loading statuses... '
            : 'offline';

        if (lastSeen) {
            const availabilityStatus = this.determineAvailability();

            if (availabilityStatus === 'online') {
                lastSeenIndicatorClassName = classes.colorGreen;
                disableButton = false;
            } else if (availabilityStatus === 'away') {
                lastSeenIndicatorClassName = classes.colorYellow;
                disableButton = true;
            }
            lastSeenIndicatorTooltipMessage = moment(lastSeen).fromNow();
        }

        const deleteModal = shared ? (
            <DeleteDevice
                close={this.onCloseDeleteModal}
                onDelete={this.onUnshareDevice}
                dialogContextContent={
                    <span>
                        you will no longer be able to connect to this device
                    </span>
                }
                title="Unshare Device"
                yesButtonContent="Yes, Unshare"
            />
        ) : (
            <DeleteDevice
                close={this.onCloseDeleteModal}
                onDelete={this.onDeleteDevice}
            />
        );

        return (
            <Card
                className={classNames(classes.root, {
                    [classes.isDragging]: isDragging
                })}
            >
                <CardHeader
                    className={classes.cardHeader}
                    title={
                        editMode ? (
                            <div className={classes.titleWrapper}>
                                <FormControl className={classes.formControl}>
                                    <Input
                                        autoComplete="name"
                                        value={name}
                                        autoFocus
                                        onChange={this.handleNameChange}
                                        inputProps={{
                                            onKeyDown: this.onKeyDown
                                        }}
                                    />
                                </FormControl>
                            </div>
                        ) : (
                            <div className={classes.titleWrapper}>
                                <Tooltip
                                    title={lastSeenIndicatorTooltipMessage}
                                    placement="bottom"
                                >
                                    <div>
                                        {platform === 'mac' && MacIcon}
                                        {platform === 'windows' && WindowsIcon}
                                        {platform === 'linux' && LinuxIcon}
                                        {platform === 'iot' && IotIcon}
                                        <CircleIcon
                                            fontSize="small"
                                            disabled={statusesLoading}
                                            className={`${lastSeenIndicatorClassName} ${
                                                classes.lastSeenIcon
                                            }`}
                                        />
                                    </div>
                                </Tooltip>
                                <Typography
                                    type="title"
                                    color="inherit"
                                    className={classes.title}
                                    onClick={this.onEditTitleClick}
                                >
                                    {name}
                                </Typography>
                            </div>
                        )
                    }
                    action={
                        editMode ? (
                            <div>
                                <SaveIcon
                                    onClick={this.handleSave}
                                    className={classes.savelIcon}
                                />
                                <CancelIcon
                                    onClick={this.onCancelUpdateClick}
                                    className={classes.cancelIcon}
                                />{' '}
                            </div>
                        ) : (
                            <div>
                                <IconButton
                                    aria-label="More"
                                    aria-owns={
                                        cardActionsAnchorEl
                                            ? 'device-card-menu'
                                            : null
                                    }
                                    aria-haspopup="true"
                                    onClick={this.handleMenuClick}
                                >
                                    <MoreVertIcon />
                                </IconButton>
                                <Menu
                                    id="device-card-menu"
                                    anchorEl={cardActionsAnchorEl}
                                    open={Boolean(cardActionsAnchorEl)}
                                    onClose={this.handleMenuClose}
                                >
                                    {!shared && (
                                        <MenuItem
                                            onClick={this.onEditTitleClick}
                                        >
                                            Edit Name
                                        </MenuItem>
                                    )}
                                    {!shared && (
                                        <MenuItem
                                            onClick={
                                                this.onChangeDeviceOwnerClick
                                            }
                                        >
                                            Change Owner
                                        </MenuItem>
                                    )}
                                    <MenuItem
                                        onClick={this.onDeleteDeviceClick}
                                    >
                                        {shared
                                            ? 'Un Share with me'
                                            : 'Delete device'}
                                    </MenuItem>
                                    {/* {!shared && (
                                        <MenuItem
                                            onClick={this.onShareDeviceClick}
                                        >
                                            Share
                                        </MenuItem>
                                    )} */}
                                </Menu>
                            </div>
                        )
                    }
                />
                <CardContent
                    className={classes.cardContent}
                    disabled={statusesLoading}
                >
                    <Typography className={classes.section}>
                        IP Address:
                        <Tooltip
                            onOpen={this.onIpAddressTooltipOpen}
                            onClose={this.onIpAddressTooltipClose}
                            title={ipAddressTooltipTitle}
                            open={ipAddressTooltipOpenState}
                        >
                            <CopyToClipboard
                                text={ip}
                                onCopy={this.onIpAddressCopied}
                            >
                                <strong style={{ cursor: 'pointer' }}>
                                    {ip}
                                </strong>
                            </CopyToClipboard>
                        </Tooltip>
                    </Typography>

                    <Typography className={classes.section}>
                        Load Average: <strong>{loadavg}</strong>
                    </Typography>
                    <Typography className={classes.section}>
                        Uptime: <strong>{uptime}</strong>
                    </Typography>
                    <Typography className={classes.section}>
                        Logged Users: <strong>{users}</strong>
                    </Typography>
                    <Typography className={classes.section}>
                        Server Time: <strong>{time}</strong>
                    </Typography>
                </CardContent>
                <CardActions className={classes.centerContent}>
                    {platform !== 'windows' && (
                        <SplitButton
                            disabled={disableButton}
                            label="SSH"
                            onClick={this.onOpenSSHConfigurationClick}
                            menuItems={this.getSSHMenuItems()}
                        />
                    )}
                    {platform !== 'windows' && (
                        <SplitButton
                            className={classes.button}
                            menuItems={this.getVNCMenuItems()}
                            disabled={disableButton}
                            label="VNC"
                            onClick={this.onOpenVNCConfigurationClick}
                        />
                    )}
                    {platform === 'windows' && (
                        <SplitButton
                            className={classes.button}
                            menuItems={this.getRDPMenuItems()}
                            disabled={disableButton}
                            label="RDP"
                            onClick={this.onOpenRDPConfigurationClick}
                        />
                    )}
                    <Button
                        className={classes.button}
                        size="small"
                        color="primary"
                        onClick={this.onProxyClick}
                        disabled={disableButton}
                    >
                        Proxy
                    </Button>
                    <Button
                        className={classes.button}
                        size="small"
                        color="primary"
                        onClick={this.onHTTPClick}
                        disabled={disableButton}
                    >
                        HTTPS
                    </Button>
                </CardActions>
                {modal === 'delete' && deleteModal}
                {modal === 'ssh' && (
                    <SSHModal
                        sshConfig={sshConfig}
                        close={this.onCloseSSHModal}
                    />
                )}
                {modal === 'proxy' && (
                    <ProxyModal
                        proxyConfig={proxyConfig}
                        close={this.onCloseProxyModal}
                    />
                )}
                {modal === 'vnc' && (
                    <VNCModal
                        vncConfig={vncConfig}
                        close={this.onCloseVNCModal}
                    />
                )}
                {modal === 'rdp' && (
                    <RDPModal
                        rdpConfig={rdpConfig}
                        close={this.onCloseRDPModal}
                    />
                )}
                {modal === 'custom-tunnel' && (
                    <TunnelParamsModal
                        close={this.onCloseModal}
                        deviceId={device._id}
                        deviceName={device.name}
                    />
                )}
                {modal === 'share' && (
                    <ShareModal
                        close={this.onCloseModal}
                        deviceId={device._id}
                    />
                )}
                {modal === 'changeOwner' && (
                    <ChangeDeviceOwnerModal
                        close={this.onCloseModal}
                        deviceId={device._id}
                    />
                )}
            </Card>
        );
    }
}

export default withStyles(styles)(Device);
