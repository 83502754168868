export const BASE_DOMAIN = process.env.REACT_APP_API_URL;

export const TERMINAL_URL = process.env.REACT_APP_TERMINAL_URL;

export const MQTT_SERVER_URL = process.env.REACT_APP_MQTT_SERVER_URL;

export const MQTT_ENV = process.env.REACT_APP_MQTT_ENV;

export const PYTHON_CLIENT_URL = process.env.REACT_APP_PYTHON_CLIENT_URL;

export const MAIN_TITLE = process.env.REACT_APP_MAIN_TITLE || 'Tunnel In -';
export const PROXY_HELP_URL =
    process.env.REACT_APP_PROXY_HELP_URL ||
    'https://tunnelin.com/blog/tag/proxy';
export const RDP_HELP_URL =
    process.env.REACT_APP_RDP_HELP_URL || 'https://tunnelin.com/blog/tag/rdp';
export const VNC_HELP_URL =
    process.env.REACT_APP_VNC_HELP_URL || 'https://tunnelin.com/blog/tag/vnc';
export const SSH_HELP_URL =
    process.env.REACT_APP_SSH_HELP_URL || 'https://tunnelin.com/blog/tag/ssh';
