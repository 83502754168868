// @flow
import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { Tooltip } from '@material-ui/core';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Avatar from '@material-ui/core/Avatar';
import DevicesIcon from '@material-ui/icons/Devices';
import TunnelIcon from '@material-ui/icons/Looks';
import HelpIcon from '@material-ui/icons/HelpOutline';
import DoneSharpIcon from '@material-ui/icons/DoneSharp';
import FormControl from '@material-ui/core/es/FormControl';
import InputLabel from '@material-ui/core/es/InputLabel';
import Select from '@material-ui/core/es/Select';
import Input from '@material-ui/core/es/Input';

import Profile from '../Profile';
import Settings from '../Settings';
import About from '../About';

import { getGravatarUrl } from '../../utils/user-utils';
import { hasGravatar } from '../../api/general-api';

import styles from './styles';
import './index.css';

import type { User } from '../../types/user';
import AddOrganization from '../OrganizationUsers/AddOrganization';
import { createOrganization } from '../../api/organization-api';
import {
    constructGlobalErrorMessage,
    parseErrorMessage
} from '../../utils/global-message-util';
import Authorities, { isComponentAllowed } from '../../containers/Authorities';
import {
    ROUTES_DEVICES_AUTH,
    ROUTES_TUNNELS_AUTH,
    ROUTES_USERS_AUTH,
    ROUTES_USAGE_AUTH
} from '../../constants/authorities';
import CouponCode from '../../containers/CouponCode';

type HeaderProps = {
    classes: {
        root: string,
        tabs: string,
        tab: string,
        toolbar: string,
        purpleAvatar: string,
        avatarButton: string
    },
    loggedInUser: User,
    actions: {
        logout: () => void
    },
    handleChange: (value: string) => void,
    activeTabIndex: number | boolean,
    history: { replace: (path: string) => void }
};

class Header extends React.Component<
    HeaderProps,
    { anchorEl: any, profilePic: string, modal: string }
> {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        loggedInUser: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            logout: PropTypes.func.isRequired,
            changeOrganization: PropTypes.func.isRequired,
            setGlobalMessageError: PropTypes.func.isRequired
        }).isRequired,
        handleChange: PropTypes.func.isRequired,
        activeTabIndex: PropTypes.oneOfType([PropTypes.bool, PropTypes.number])
            .isRequired,
        history: PropTypes.shape({
            replace: PropTypes.func.isRequired
        }).isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            profilePic: '',
            modal: ''
        };
    }

    componentDidMount() {
        const { loggedInUser } = this.props;
        this.determineProfilePic(loggedInUser);
    }

    componentDidUpdate(prevProps: HeaderProps) {
        const { loggedInUser } = this.props;
        if (loggedInUser !== prevProps.loggedInUser) {
            this.determineProfilePic(loggedInUser);
        }
    }

    handleChange = (event, value) => {
        const { handleChange } = this.props;
        handleChange(value);
    };

    handleAvatarClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleAvatarMenuClose = () => {
        this.setState({ anchorEl: null });
    };

    handleProfileClick = () => {
        this.handleAvatarMenuClose();
        this.setState({ modal: 'profile' });
    };

    handleSettingsClick = () => {
        this.handleAvatarMenuClose();
        this.setState({ modal: 'settings' });
    };

    handleManageUsersClick = () => {
        const { history } = this.props;
        this.handleAvatarMenuClose();
        history.replace('/users');
    };

    handleAddOrganizationClick = () => {
        this.handleAvatarMenuClose();
        this.setState({ modal: 'addOrganization' });
    };

    handleUsageClick = () => {
        const { history } = this.props;
        this.handleAvatarMenuClose();
        history.replace('/usage');
    };

    addOrganization = (name, description) => {
        const {
            actions: { changeOrganization, setGlobalMessageError }
        } = this.props;
        createOrganization({
            name,
            description
        }).subscribe(
            data => {
                if (data && data.data) {
                    changeOrganization({
                        organizationId: data.data._id
                    });
                }
            },
            error => {
                setGlobalMessageError(
                    constructGlobalErrorMessage(parseErrorMessage(error))
                );
            }
        );
    };

    handleChangeOrgClick = event => {
        const { actions } = this.props;
        actions.changeOrganization({
            organizationId: event.target.value
        });
    };

    handleAboutClick = () => {
        this.handleAvatarMenuClose();
        this.setState({ modal: 'about' });
    };

    handleApplyCouponCodeClick = () => {
        this.handleAvatarMenuClose();
        this.setState({ modal: 'addCode' });
    };

    handleLogoutClick = () => {
        this.handleAvatarMenuClose();
        const {
            actions: { logout }
        } = this.props;
        logout();
    };

    handleModalClose = () => {
        this.setState({ modal: '' });
    };

    determineProfilePic(user) {
        const { profilePic } = user;
        if (!profilePic) {
            this.setState({ profilePic: '' });
        } else {
            const { type, url } = profilePic;
            if (type === 'gravatar') {
                const { email } = user;
                hasGravatar(email)
                    .then(({ data }) => {
                        if (data) {
                            this.setState({
                                profilePic: getGravatarUrl(email)
                            });
                        } else {
                            this.setState({ profilePic: '' });
                        }
                    })
                    .catch(() => {
                        this.setState({ profilePic: '' });
                    });
            } else if (url) {
                this.setState({ profilePic: url });
            } else {
                this.setState({ profilePic: '' });
            }
        }
    }

    render() {
        const { anchorEl, profilePic, modal } = this.state;
        const { classes, loggedInUser, activeTabIndex } = this.props;
        const { fullName, orgs } = loggedInUser;

        const codeInfo = ((loggedInUser.couponCode || [])[0] || {}).name;
        const orgSelector =
            !loggedInUser || !orgs || orgs.length < 2 ? (
                ''
            ) : (
                <FormControl style={{ width: 200, marginTop: 16 }}>
                    <InputLabel htmlFor="age-helper">Organization</InputLabel>
                    <Select
                        value={orgs.find(f => f.default)._id}
                        onChange={this.handleChangeOrgClick}
                        input={<Input name="age" id="age-helper" />}
                    >
                        {orgs.map(r => (
                            <MenuItem key={r._id} value={r._id}>
                                {r.name !== 'Personal' ||
                                r.createdBy === loggedInUser._id
                                    ? r.name
                                    : r.description
                                    ? r.description.replace('Organization', '')
                                    : r.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            );
        let avatarCmp;
        if (profilePic) {
            avatarCmp = (
                <IconButton
                    disableRipple
                    aria-owns={anchorEl ? 'header-avatar-menu' : null}
                    aria-haspopup="true"
                    onClick={this.handleAvatarClick}
                    className={classes.avatarButton}
                >
                    <Avatar src={profilePic} />
                </IconButton>
            );
        } else {
            avatarCmp = (
                <Avatar className={classes.purpleAvatar}>
                    <Button
                        aria-owns={anchorEl ? 'header-avatar-menu' : null}
                        aria-haspopup="true"
                        onClick={this.handleAvatarClick}
                        className={classes.avatarButton}
                    >
                        {fullName.charAt(0)}
                    </Button>
                </Avatar>
            );
        }

        return (
            <AppBar className={classes.root} position="static" color="default">
                <Toolbar className={classes.toolbar}>
                    <Typography type="title" color="inherit">
                        TunnelIn
                    </Typography>
                    <Tabs
                        className={classes.tabs}
                        value={activeTabIndex}
                        onChange={this.handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="on"
                    >
                        {isComponentAllowed(
                            ROUTES_DEVICES_AUTH,
                            loggedInUser
                        ) && (
                            <Tab
                                icon={
                                    <DevicesIcon className="material-icons" />
                                }
                                className={classNames(classes.tab, {
                                    headerNavTab: true
                                })}
                                label="Devices"
                            />
                        )}
                        {isComponentAllowed(
                            ROUTES_TUNNELS_AUTH,
                            loggedInUser
                        ) && (
                            <Tab
                                data-cmpauthkey={ROUTES_TUNNELS_AUTH}
                                icon={<TunnelIcon className="material-icons" />}
                                className={classNames(classes.tab, {
                                    headerNavTab: true
                                })}
                                label="Tunnels"
                            />
                        )}

                        <Tab
                            icon={<HelpIcon className="material-icons" />}
                            className={classNames(classes.tab, {
                                headerNavTab: true
                            })}
                            label="Help"
                        />
                    </Tabs>
                    {orgSelector}
                    {avatarCmp}
                    {codeInfo && (
                        <Tooltip
                            title={`You have activated ${codeInfo} code`}
                            placement="top"
                        >
                            <DoneSharpIcon />
                        </Tooltip>
                    )}
                    <Menu
                        id="header-avatar-menu"
                        anchorEl={anchorEl}
                        open={!!anchorEl}
                        onClose={this.handleAvatarMenuClose}
                    >
                        <MenuItem onClick={this.handleProfileClick}>
                            Profile
                        </MenuItem>
                        <MenuItem onClick={this.handleSettingsClick}>
                            Settings
                        </MenuItem>
                        <Authorities>
                            <MenuItem
                                data-cmpauthkey={ROUTES_USERS_AUTH}
                                onClick={this.handleManageUsersClick}
                            >
                                Manage users
                            </MenuItem>
                        </Authorities>
                        <Authorities>
                            <MenuItem
                                data-cmpauthkey="add.organization.action"
                                onClick={this.handleAddOrganizationClick}
                            >
                                Add Organization
                            </MenuItem>
                        </Authorities>
                        <Authorities data-cmpauthkey={ROUTES_USAGE_AUTH}>
                            <MenuItem onClick={this.handleUsageClick}>
                                Usage
                            </MenuItem>
                        </Authorities>
                        <MenuItem onClick={this.handleApplyCouponCodeClick}>
                            Apply Coupon Code
                            {codeInfo && (
                                <Tooltip
                                    title={`You have activated ${codeInfo} code`}
                                    placement="top"
                                >
                                    <DoneSharpIcon
                                        style={{ width: 15, height: 20 }}
                                    />
                                </Tooltip>
                            )}
                        </MenuItem>
                        <MenuItem onClick={this.handleAboutClick}>
                            About
                        </MenuItem>
                        <MenuItem onClick={this.handleLogoutClick}>
                            Logout
                        </MenuItem>
                    </Menu>
                </Toolbar>
                {modal === 'profile' && (
                    <Profile close={this.handleModalClose} />
                )}
                {modal === 'settings' && (
                    <Settings close={this.handleModalClose} />
                )}
                {modal === 'about' && <About close={this.handleModalClose} />}
                {modal === 'addCode' && (
                    <CouponCode
                        fullScreen={false}
                        close={this.handleModalClose}
                    />
                )}
                {modal === 'addOrganization' && (
                    <AddOrganization
                        close={this.handleModalClose}
                        onSave={this.addOrganization}
                    />
                )}
            </AppBar>
        );
    }
}

export default withRouter(withStyles(styles)(Header));
