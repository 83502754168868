import React from 'react';
import PropTypes from 'prop-types';
import { GUAC_CLIENT_STATE_DISCONNECTED } from '../../../constants/guac-constants';
import GuacClient from '../GuacClient';
import Settings from './Settings';
import clientWrapper from './clientWrapper';

// type VNCTerminalPropTypes = {
//     deviceName: string,
//     token: string,
//     onSettingsDataReady: (data: any) => void
// };

const Client = clientWrapper(GuacClient);

class VNCTerminal extends React.Component {
    static propTypes = {
        deviceName: PropTypes.string.isRequired,
        token: PropTypes.string.isRequired,
        onSettingsDataReady: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            showSettings: props.token === ''
        };
    }

    componentDidUpdate(prevProps) {
        const { token: oldToken } = prevProps;
        const { token: newToken } = this.props;
        if (oldToken !== newToken) {
            this.setState({ showSettings: newToken === '' });
        }
    }

    onSettingsIconClick = () => {
        this.setState({ showSettings: true });
    };

    onSettingCloseClick = () => {
        this.setState({ showSettings: false });
    };

    onGuacClientError = () => {
        this.setState({ showSettings: true });
    };

    onGuacClientStateChange = state => {
        const { showSettings } = this.state;
        if (state === GUAC_CLIENT_STATE_DISCONNECTED) {
            if (!showSettings) {
                window.close();
            }
        }
    };

    render() {
        const { token, onSettingsDataReady } = this.props;
        const { showSettings } = this.state;
        if (showSettings) {
            return (
                <Settings
                    onDataReady={onSettingsDataReady}
                    onCloseClick={this.onSettingCloseClick}
                    closable={token !== ''}
                />
            );
        }

        return (
            <Client
                token={token}
                onError={this.onGuacClientError}
                onGuacClientStateChange={this.onGuacClientStateChange}
                onSettingsIconClick={this.onSettingsIconClick}
            />
        );
    }
}

export default VNCTerminal;
