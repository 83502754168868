// @flow
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { wrapActionCreators } from '../../utils/container-util';
import {
    selectDevices,
    getDevicesError,
    getDevicesLoading,
    getTotalCount,
    updateDeviceLoading,
    selectDevicesStatusesLoading
} from '../../selectors/devices-selectors';
import { selectGroupData } from '../../selectors/groups-selectors';
import { getLoggedInUser } from '../../selectors/app-selectors';
import { getDevices, updateDevice } from '../../actions/device-actions';
import { getGroups } from '../../actions/group-actions';
import Devices from '../../components/Devices';

class DevicesHOC extends Component<
    {
        actions: {
            getDevices: () => void,
            updateDevice: () => void,
            getGroups: () => void,
            tabs: string,
            tab: string,
            toolbar: string,
            purpleAvatar: string
        },
        loggedInUser: any,
        devices: any[],
        groups: any[],
        totalCount: number,
        devicesLoading: boolean,
        devicesError: string,
        devicesUpdating: string[]
    },
    {}
> {
    static propTypes = {
        actions: PropTypes.shape({
            getDevices: PropTypes.func.isRequired,
            updateDevice: PropTypes.func.isRequired,
            getGroups: PropTypes.func.isRequired
        }).isRequired,
        devices: PropTypes.array.isRequired,
        statusesLoading: PropTypes.bool.isRequired,
        groups: PropTypes.array.isRequired,
        totalCount: PropTypes.number.isRequired,
        devicesLoading: PropTypes.bool.isRequired,
        devicesUpdating: PropTypes.array.isRequired,
        devicesError: PropTypes.string.isRequired,
        loggedInUser: PropTypes.object.isRequired
    };

    intervalId = null;

    componentDidMount() {
        const {
            actions: { getGroups, getDevices }
        } = this.props;
        getGroups();
        getDevices();
    }

    componentWillUnmount() {
        clearInterval(this.intervalId);
    }

    render() {
        const {
            actions,
            devices,
            statusesLoading,
            totalCount,
            devicesLoading,
            devicesError,
            devicesUpdating,
            groups,
            loggedInUser
        } = this.props;
        return (
            <Devices
                actions={actions}
                loggedInUser={loggedInUser}
                devices={devices}
                statusesLoading={statusesLoading}
                groups={groups}
                devicesUpdating={devicesUpdating}
                totalCount={totalCount}
                devicesLoading={devicesLoading}
                devicesError={devicesError}
            />
        );
    }
}

const mapStateToProps = createStructuredSelector({
    devices: selectDevices(),
    statusesLoading: selectDevicesStatusesLoading(),
    groups: selectGroupData(),
    devicesUpdating: updateDeviceLoading(),
    totalCount: getTotalCount(),
    devicesLoading: getDevicesLoading(),
    devicesError: getDevicesError(),
    loggedInUser: getLoggedInUser()
});

const mapDispatchToProps = wrapActionCreators({
    getDevices,
    getGroups,
    updateDevice
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DevicesHOC);
