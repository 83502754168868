// @flow
import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import connect from 'react-redux/es/connect/connect';
import { createStructuredSelector } from 'reselect';
import Header from '../Header';
import Devices from '../Devices';
import Tunnels from '../Tunnels';
import OrgUsers from '../../components/OrganizationUsers';
import ChangeDeviceOwner from '../Devices/ChangeDeviceOwner';
import { wrapActionCreators } from '../../utils/container-util';
import { getStatuses } from '../../actions/device-actions';
import Usage from '../../components/Usage';
import { getDefaultRoute, isComponentAllowed } from '../Authorities';
import { getLoggedInUser } from '../../selectors/app-selectors';
import {
    ROUTES_DEVICES_AUTH,
    ROUTES_TUNNELS_AUTH,
    ROUTES_CHANGEDEVICEOWNER_AUTH,
    ROUTES_USERS_AUTH,
    ROUTES_USAGE_AUTH
} from '../../constants/authorities';
import WithHelmet from '../WithHelmet';

import tunnelinDevicesImage from '../../assets/images/tunnelin_devices.jpg';
import tunnelinTunnelsImage from '../../assets/images/tunnelin_tunnels.jpg';

const DevicesWithHelmet = WithHelmet({
    title: 'Devices',
    meta: [
        { name: 'og:url', content: '/devices' },
    ],
    image: {
        src: tunnelinDevicesImage,
        width: 1200,
        height: 630,
        type: 'image/jpeg'
    },
    description: `Tunnel In - Access your devices from the web to view CPU usage, heartbeat, etc... Create tunnels (SSH, VNC, RDP, and HTTPS) from this page.`
});

const TunnelsWithHelmet = WithHelmet({
    title: 'Tunnels',
    meta: [
        { name: 'og:url', content: '/tunnels' },
    ],
    image: {
        src: tunnelinTunnelsImage,
        width: 1200,
        height: 630,
        type: 'image/jpeg'
    },
    description: `Tunnel In - Create reliable TCP tunnels to expose protocols like SSH, VNC, RDP, HTTPS. Use a cloud-based firewall for access control.`
});

const styles = () => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1
    }
});

class WithHeader extends Component<
    {
        classes: { root: string },
        actions: {
            getStatuses: () => void
        },
        loggedInUser: any
    },
    {}
> {
    componentDidMount() {
        this.fetchDeviceStatuses();
        this.intervalId = setInterval(this.fetchDeviceStatuses, 60000);
    }

    componentWillUnmount() {
        clearInterval(this.intervalId);
    }

    fetchDeviceStatuses = () => {
        const {
            actions: { getStatuses }
        } = this.props;
        getStatuses();
    };

    render = () => {
        const { classes, loggedInUser } = this.props;
        return (
            <div className={classes.root}>
                <Header />
                <Switch>
                    {isComponentAllowed(ROUTES_DEVICES_AUTH, loggedInUser) && (
                        <Route
                            path="/devices"
                            component={DevicesWithHelmet(Devices)}
                        />
                    )}
                    {isComponentAllowed(ROUTES_TUNNELS_AUTH, loggedInUser) && (
                        <Route
                            path="/tunnels"
                            component={TunnelsWithHelmet(Tunnels)}
                        />
                    )}
                    {isComponentAllowed(
                        ROUTES_CHANGEDEVICEOWNER_AUTH,
                        loggedInUser
                    ) && (
                        <Route
                            path="/changedeviceowner"
                            component={WithHelmet({
                                title: 'Change Device Owner'
                            })(ChangeDeviceOwner)}
                        />
                    )}
                    {isComponentAllowed(ROUTES_USERS_AUTH, loggedInUser) && (
                        <Route
                            path="/users"
                            component={WithHelmet({
                                title: 'User Management'
                            })(OrgUsers)}
                        />
                    )}
                    {isComponentAllowed(ROUTES_USAGE_AUTH, loggedInUser) && (
                        <Route
                            path="/usage"
                            component={WithHelmet({
                                title: 'Data Usage'
                            })(Usage)}
                        />
                    )}
                    <Redirect to={`/${getDefaultRoute(loggedInUser)}`} />
                </Switch>
            </div>
        );
    };
}

WithHeader.propTypes = {
    classes: PropTypes.shape({
        root: PropTypes.string.isRequired
    }).isRequired,
    actions: PropTypes.shape({
        getStatuses: PropTypes.func.isRequired
    }).isRequired,
    loggedInUser: PropTypes.object.isRequired
};

const mapStateToProps = createStructuredSelector({
    loggedInUser: getLoggedInUser()
});

const mapDispatchToProps = wrapActionCreators({
    getStatuses
});

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(WithHeader)
);
